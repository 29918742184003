export function dayOfTheYear(date){
	const start = new Date(date.getFullYear(), 0, 0);
	const diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
	return Math.floor(diff / (1000 * 60 * 60 * 24));
}

export function getJsDateFromExcel(excelDate) {
	const date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
	return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function getExcelDateFromJs(jsDate) {
	const returnDateTime = 25569 + ((jsDate.getTime() - (jsDate.getTimezoneOffset() * 60000)) / (86400 * 1000));
	return returnDateTime.toString();
}

export function _to_number(v){
	if(v || v === 0){
		v = v*1;
		if(!isNaN(v)) return v;
	}
	return false;
}

export function _combine(data){
	var arr = [];
	for (var i = 0; i < data.length; i++){
		arr = arr.concat(data[i]);
	}
	return arr;
}