import {getJsDateFromExcel, getExcelDateFromJs, dayOfTheYear} from "./helpers";

export function DATE(year, month, day){
	const date = new Date(year, month-1, day);
	return getExcelDateFromJs(date);
}

export function TIME(hour, minute, second){
	const date = new Date(1900, 0, 1, hour, minute, second);
	return getExcelDateFromJs(date);
}

export function DAY(date){
	return getJsDateFromExcel(date).getDate();
}

export function MONTH(date){
	return getJsDateFromExcel(date).getMonth()+1;
}

export function YEAR(date){
	return getJsDateFromExcel(date).getFullYear();
}

export function NOW(){
	const date = new Date();
	return getExcelDateFromJs(date);
}

export function DATEDIF(start, end, unit){
	if(unit != "D"){
		start = getJsDateFromExcel(start);
		end = getJsDateFromExcel(end);
	}

	switch(unit){
		case "Y":
			return end.getFullYear() - start.getFullYear();
		case "M":{
			let months = (end.getFullYear() - start.getFullYear()) * 12;
			return months + end.getMonth() - (start.getMonth());
		}
		case "D":{ 
			return Math.floor(end - start);
		}
		case "MD":{
			const diff = end.getDate() - start.getDate();
			if(diff < 0){
				const startDateDays = new Date(start.getFullYear(), start.getMonth() + 1, 0).getDate();
				return end.getDate() + startDateDays - start.getDate();
			}
			return diff;
		}
		case "YM":{
			const months = end.getMonth() - start.getMonth();
			return months < 0 ? 12 + months : months;
		}
		case "YD":{
			const diff = dayOfTheYear(end) - dayOfTheYear(start);
			return diff < 0 ? 365 + diff : diff;
		}
	}
}