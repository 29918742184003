import group from "./undo_group";
import {updateMath} from "../math/updater";
import {pasteCondition} from "../operations/conditional_formats";
import {pasteDropdown} from "../operations/dropdown";
import {pasteFilter} from "../operations/filter";
import {pasteSpan} from "../operations/spans";

var styledata, clipdata, origin, cutted, updateFilters;

export function init(view){
	const keyC = 67; //keyboard 'c' code
	const keyX = 88; //keyboard 'x' code
	var grid = view.$$("cells");
	
	grid.attachEvent("onKeyPress", (code, e) => {
		if((code === keyC || code === keyX) && (e.ctrlKey || e.metaKey) && grid.getSelectedId()){
			styledata = _get_sel_style(view, grid);
			clipdata = document.getElementsByClassName("webix_clipbuffer")[0].value;
			origin = grid.getSelectArea();
			cutted = (code === keyX)*1;
			if(cutted && Object.keys(view._table._ssFilters).length !== 0)
				updateFilters = allFiltersInZone(view._table._ssFilters, origin, view.config.columnCount);
		}
	});

	if (!view.config.readonly) {
		grid.attachEvent("onPaste", (text) => {
			_clip_to_sel(view, grid, text);
		});
	}
}

function _clip_to_sel(view, grid, text){
	var leftTop = grid.getSelectArea();
	if (!leftTop) return;
	var start = leftTop.start;
	var fromSheet = text === clipdata;

	var data = fromSheet ? styledata : webix.csv.parse(text, grid.config.delimiter);
	if(!fromSheet && view.config.clipboardDecimalDelimiter)
		data = _changeDelimiter(data, view.config.clipboardDecimalDelimiter);

	var translate = { id:"move", column:0, row: 0, cut:cutted };
	if (fromSheet){
		translate.column = start.column - origin.start.column;
		translate.row = start.row - origin.start.row;
	} else {
		cutted = 0;
	}

	group.set(function(){
		if (updateFilters)
			view.removeFilters();

		_add_row_col(view, start, data);

		if (cutted === 1) {
			for (let row = origin.start.row; row <= origin.end.row; row++) {
				for (let column = origin.start.column; column <= origin.end.column; column++) {
					view.setCellValue(row, column, null);
					view.setStyle(row, column, null);
				}
			}
		}

		if (data.length == 1 && data[0].length == 1 && !updateFilters) {
			view.eachSelectedCell(function(cell) {
				var subtrans = {
					id: "move",
					column: translate.column + cell.column * 1 - start.column,
					row: translate.row + cell.row * 1 - start.row,
					cut:cutted
				};
				_clipboardToTable(view, cell.row, cell.column, data[0][0], fromSheet, subtrans);
			});
		} else {
			grid.mapCells(
				start.row,
				start.column,
				data.length,
				null,
				function(value, row, col, row_ind, col_ind) {
					if (data[row_ind] && data[row_ind].length > col_ind) {
						var cdata = data[row_ind][col_ind];
						_clipboardToTable(view, row, col, cdata, fromSheet, translate);
					}
				},
				true
			);
		}

		if (cutted === 1){
			//cut and cleared
			cutted = 2;
			updateFilters = false;
		}
	});
	
	view.refresh();
}

function _clipboardToTable(view, row, col, cdata, fromSheet, translate){
	var newValue = cdata;

	if (typeof cdata === "object"){
		if (cdata.math)
			newValue = updateMath(cdata.math, translate, origin);
		else 
			newValue = cdata.text;

		if(cdata.style && cdata.style.props){
			const style = view.addStyle(cdata.style.props);
			view.setStyle(row, col, style);
		}

		var extra = cdata.extra;
		if(extra){
			if(extra.condition)
				pasteCondition(view, extra, row, col, cutted);
			if(extra.dropdown)
				pasteDropdown(view, extra, row, col, cutted, translate);
			if(extra.filter && updateFilters)
				pasteFilter(view, extra, row, col, cutted, translate);
		}
		pasteSpan(view, extra, row, col, cutted, translate);
	}

	view.setCellValue(row, col, newValue);
}

function _get_sel_style(view, grid){
	var data = [];
	var row, last;

	grid.mapSelection(function(value, id, col) {
		if (id != last){
			row = []; data.push(row);
			last = id;
		}

		var item = grid.getItem(id);
		var math = item["$"+col];
		var obj = { text:value, math:math, style:view.getStyle(id, col) };

		var condition = view.conditions.get(id,col);
		var editor = view.getCellEditor(id, col);
		var filter = view.getCellFilter(id, col);
		var span = view._table.getSpan(id, col);

		if (editor || filter || condition || span){
			var extra = { row:id, col:col };
			if (condition) extra.condition = condition;
			if (editor) extra.dropdown = editor;
			if (filter) extra.filter = filter;
			if (span) extra.span = span;
			obj.extra = extra;
		}
		
		row.push(obj);
		return value;
	});

	return data;
}

function allFiltersInZone(extra, origin, columnCount){
	var filters = Object.keys(extra);
	var startRow = Math.min.apply(null, filters);
	var endRow = Math.max.apply(null, filters);
	var startCol = columnCount;
	var endCol = 1;

	for (var i = startRow; i <= endRow; i++){
		if(extra[i]){
			var columns = Object.keys(extra[i]);
			startCol = Math.min(startCol, Math.min.apply(null, columns));
			endCol = Math.max(endCol, Math.max.apply(null, columns));
		}
	}

	if(origin.start.row <= startRow && origin.end.row >= endRow && origin.start.column <= startCol && origin.end.column >= endCol)
		return true;
	else
		return false;
}
//dynamically increase rows and columns according to pasted data size
function _add_row_col(view, start, data){
	var maxRow = start.row + data.length - 1;
	var maxCol = start.column + data[0].length - 1;

	if(maxRow > view.config.rowCount || maxCol > view.config.columnCount){
		var action = { id:"add" };
		if (maxRow > view.config.rowCount) {
			action.group = "row";
			view.callEvent("onCommand", [
				action, 
				{ row: view.config.rowCount + 1 }, 
				{ row: maxRow }
			]);
		}
		if (maxCol > view.config.columnCount) {
			action.group = "column";
			view.callEvent("onCommand", [
				action, 
				{ column: view.config.columnCount + 1 },
				{ column: maxCol }
			]);
		}
	}
}

function _changeDelimiter(data, clipboardDecimalDelimiter){
	for(var i = 0; i < data.length; i++)
		for(var k = 0; k < data[i].length; k++){
			var record = data[i][k].toString().replace(clipboardDecimalDelimiter, ".");
			if(webix.rules.isNumber(record))
				data[i][k] = record;
		}
	return data;
}