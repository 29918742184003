import { style_names } from "../operations/styles";
import { getType } from "../operations/types";
import { defaultStyles} from "../toolbar/defaults";
import { getFormatSource } from "../operations/formats";

export function init(view){
	view.$exportView = function(options){
		webix.extend(options, { header: false, rawValues: true, spans: true, styles:true, math:true, xCorrection:1, ignore:{rowId:true}});
		if(options.export_mode =="excel")
			return _exportExcel(view, options);
		else
			return view._table;
	};
}

function _exportExcel(view, options){
	if(options.sheets === true) 
		options.sheets  = view._sheets.map(function(s){ return s.name;});
	else if(!options.sheets || !options.sheets.length)
		options.sheets = [view._activeSheet];
	else if(typeof options.sheets == "string")
		options.sheets = [options.sheets];

	options.dataOnly = true;

	let data = [];
	let active = view._activeSheet;

	for(let i = 0; i<options.sheets.length; i++){
		const sheet = options.sheets[i];
		const id = sheet.id || sheet;

		view.showSheet(id);
		options.xCorrection = view.$$("cells").config.header ? 1 : 0;

		const sheetOptions = sheet.options ? webix.extend(sheet.options, options) : webix.copy(options);
		if(!sheetOptions.name)
			sheetOptions.name = id;

		data = data.concat(webix.toExcel(view._table, sheetOptions));
		data[i].ranges = [];

		let ranges = view.ranges.getRanges();
		for(let r = 0; r<ranges.length; r++){
			data[i].ranges.push({
				Sheet:i,
				Name:ranges[r].name,
				Ref:sheetOptions.name+"!"+ranges[r].range.replace(/(\w)/gi, function(match){ return "$"+match; })
			});
		}
		if(sheetOptions.styles)
			data[i].styles = _getStyles(view, sheetOptions);
	}
	view.showSheet(active);

	delete options.dataOnly;
	return data;
}

function _safeColor(str){
	str = str.substring(1);
	if(str.length === 3) str = str+str;
	return str;
}

function _getDefaults(){
	let d = defaultStyles;
	return {
		font:{
			sz:d["font-size"].replace("px", "")*0.75,
			name:d["font-family"].replace(/'|,.*$/g, ""),
			//we do not export default color, but it is the right place to do it
		},
		alignment:{
			horizontal:d["text-align"],
			vertical:d["vertical-align"]=="middle"?"center":d["vertical-align"],
			wrapText:d["white-space"]!="nowrap"
		}
	};
}

function _getStyles(view, options){

	view.compactStyles();

	let result = [];
	let cached = {};
	const defaults = _getDefaults();

	if(options.docHeader) result  = result.concat([{0:_getDocStyle(options.docHeader.css, defaults)}, {}]);
	if(options.header) result.push({});

	const delta = result.length;

	const grid = view.$$("cells");
	const columns = grid.config.columns;

	let rIndex = delta;
	grid.eachRow((row) =>{
		for (let cIndex = options.xCorrection; cIndex < columns.length; cIndex++){
			const css = view.getStyle(row, columns[cIndex].id);
			const index = cIndex - options.xCorrection;

			result[rIndex] = result[rIndex] || {};

			let styles;
			if(css){
				if(cached[css.id])
					styles = cached[css.id];
				else{
					styles = _getCellStyle(css.text);
					for(let name in defaults)
						webix.extend(styles[name], defaults[name]);
					cached[css.id] = styles;
				}
			}
			else
				styles = defaults;

			result[rIndex][index] = webix.copy(styles);
			result[rIndex][index].type = getType(view, row, columns[cIndex].id);
		}
		rIndex++;
	});

	if(options.docFooter) result  = result.concat([{},{0:_getDocStyle(options.docFooter.css, defaults)}]);

	return result;
}

function _getCellStyle(styles){
	let str = styles.split(";"), stl = { font:{}, alignment:{}, border:{}};

	for(let s = 0; s<str.length; s++){
		if(str[s]){
			switch (style_names[s]){
				case "color": 
					stl.font.color = {rgb:_safeColor(str[s])};
					break;
				case "background":{
					let fill = _safeColor(str[s]);
					if(fill && fill.toLowerCase() !=="ffffff")
						stl.fill = { fgColor:{ rgb:fill}};
					break;
				}	
				case "text-align":
					stl.alignment.horizontal = str[s];
					break;
				case "font-family":
					stl.font.name = str[s].replace(/'|,.*$/g, ""); // cut off fallback font
					break;
				case "font-size":
					stl.font.sz = str[s].replace("px", "")*0.75; //px to pt conversion
					break;
				case "font-style":
					stl.font.italic = str[s] == "italic";
					break;
				case "text-decoration":
					stl.font.underline = str[s] == "underline";
					break;
				case "font-weight":
					stl.font.bold = str[s] == "bold";
					break;
				case "vertical-align":
					stl.alignment.vertical = str[s] == "middle"?"center":str[s];
					break;
				case "wrap":
					stl.alignment.wrapText = str[s] == "wrap";
					break;
				case "borders":
					break;
				case "format":
					stl.format = getFormatSource(str[s], true) || "";
					break;
				case "border-right":
					stl.border.right = { color:{ rgb:_safeColor(str[s])}, style:"thin"};
					break;
				case "border-bottom":
					stl.border.bottom = { color:{ rgb:_safeColor(str[s])}, style:"thin"};
					break;
				case "border-left":
					stl.border.left = { color:{ rgb:_safeColor(str[s])}, style:"thin"};
					break;
				case "border-top":
					stl.border.top = { color:{ rgb:_safeColor(str[s])}, style:"thin"};
					break;
			}
		}
	}
	return stl;
}

function _getDocStyle(css){
	if(!css) return {};
	var str = [];
	for(let i =0; i<style_names.length; i++)
		str.push(css[style_names[i]]||"");
	return _getCellStyle(str.join(";"));
}

