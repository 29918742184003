import * as nms from "../helpers/column_names";
import {split, position} from "../math/parser";

export function updateMath(formula, action, area){
	const stack = split(formula, true);
	const max = stack.length;
	const start = area ? area.start : null;
	const end = area ? area.end : null;
	const {count, id} = action;

	for (let i = 1; i < max; i+=2) {
		//do not update cells in other sheets
		if(webix.isArray(stack[i])){
			const [sheet, cell, escaped] = stack[i];
			const quot = escaped ? "'" : "";
			stack[i] = quot + sheet + quot + "!" + cell;
			continue;
		}

		let [row, column, flags] = position(stack[i]);

		const isRowFix = flags & 1;
		const isColumnFix = flags & 2;
		const range = checkRange(stack, i);

		// for cut operations we need to check whether referenced values are inside cut block. if so - update them.
		if (id == "move" && (!action.cut || (row >= start.row && row <= end.row && column >= start.column && column <= end.column))){
			column += isColumnFix ? 0 : action.column;
			row += isRowFix ? 0 : action.row;
		}
		else if(id == "row" || id == "column"){
			const isRow = id == "row";
			let val = isRow ? row : column;
			if(count < 0 && range == "start"){
				if(action.start <= val && position(stack[i+2])[isRow ? 0 : 1] <= action.start + Math.abs(count)){
					stack[i] = stack[i+2] = "#REF!";
					i += 2;
					continue;
				}
			}
			if(action.start <= val){
				let updated;
				if(count < 0 && action.start + Math.abs(count) > val){
					if(range){
						val = action.start - (range == "end" ? 1 : 0);
						updated = true;
					}
					else{
						stack[i] = "#REF!";
						continue;
					}
				}
				if(!updated)
					val += count;

				if(isRow)
					row = val;
				else
					column = val;
			}
		}

		if (column <= 0 || row <= 0)
			stack[i] = "#REF!";
		else
			stack[i] = (isColumnFix?"$":"") + nms.encode[column] + (isRowFix?"$":"") + row;
	}

	return stack.join("");
}

function checkRange(stack, index){
	if(stack[index+1] == ":" && stack[index+2])
		return "start";
	else if(stack[index-1] == ":" && stack[index-2])
		return "end";
}

export function updateSheetNames(formula, oldName, newName, extra){
	var stack = split(formula, true);
	for(var i = 0; i < stack.length; i++){
		if(webix.isArray(stack[i])){
			var thisSheet = stack[i][0] === oldName;
			if(!extra.multiSheet && thisSheet)
				extra.multiSheet = true;
			var sheet = thisSheet ? newName : stack[i][0];
			stack[i] = "'"+sheet+"'!"+stack[i][1];
		}
	}
	return stack.join("");
}