import {getJsDateFromExcel, getExcelDateFromJs} from "../math/methods/helpers";
import {getDateEditFormat} from "../operations/formats";

webix.editors.excel_date = webix.extend({
	createPopup:function(){
		const editor = webix.editors.excel_date;

		if(!editor.popup){
			const popup = webix.ui({
				view:"popup",
				body:{ 
					view:"calendar",
					icons:true,
					timepicker:true,
					on:{
						onChange: function(val){
							val = val[0];
							this.getParentView()._last_input_target.value = val ? getExcelDateFromJs(val) : "";
							webix.callEvent("onEditEnd", []);
						}
					}
				},
				on:{
					onEnter: function(){
						webix.callEvent("onEditEnd", []);
					}
				}
			});
			editor.popup = popup.config.id;
		}

		return editor.popup;
	},
	render:function(){
		const node = webix.html.create("div", {
			"class":"webix_dt_editor"
		}, "<input type='text'>");

		this.popup = this.createPopup();

		webix.event(node.firstChild, "click", ()=> this.getPopup().show(node));

		return node;
	},
	getPopup(){
		return webix.$$(this.popup);
	},
	showPopup:function(){
		const input = this.getInputNode();
		const popup = this.getPopup();

		popup._last_input_target = input;
		popup.show(input);

		input.setAttribute("aria-expanded", "true");
	},
	updateCalendar(value){
		const calendar = this.getPopup().getBody();

		calendar.blockEvent();
		calendar.setValue(value);
		calendar.unblockEvent();
	},
	afterRender:function(){
		this.showPopup();
	},
	setValue:function(value){
		const noDate = (value === "" || isNaN(value*1));
		value = noDate ? new Date() : getJsDateFromExcel(value);
		this.updateCalendar(value);

		const format = getDateEditFormat(this.config.row, this.config.id);
		this.getInputNode().value = format(value);
	},
	getValue:function(){
		const value = this.getInputNode().value;
		this.getPopup().hide();
		return value;
	}
}, webix.editors.text);