import {Dialog} from "./common";
import {getJsDateFromExcel, getExcelDateFromJs} from "../math/methods/helpers";
import {isDateInvalid} from "../operations/formats";
import {getDateEditFormat} from "../operations/formats";

export const action = "conditional-format";

const condition = [
	{id: ">",value: ">" },
	{id: "<",value: "<"},
	{id: "=",value: "="},
	{id: "!=",value: webix.i18n.spreadsheet.labels["conditional-not-equal"]},
	{id: "<>",value: webix.i18n.spreadsheet.labels["conditional-between"]}
];

function getConditionStyle(data){
	return data.map(item => {
		item.id = item.css;
		return item;
	});
}

export class DialogBox extends Dialog{
	_getCondition() {
		let view = this.view;
		let forms = this.$dialog.getBody().getChildViews()[0].getChildViews()[0].getChildViews();
		let data = [];

		forms.forEach(form => {
			if (!form.getValues) return;
			let values = form.getValues();
			let item = [];
			item.push(values.condition);

			if(values.condition !== "<>"){
				item.push(this._normalizeValue(values.value));
			} else {
				item.push([this._normalizeValue(values.value), this._normalizeValue(values.value2)]);
			}
			item.push(values.style);
			for(var i=0; i < item.length; i++){
				if(item[i] === "")
					return false;
			}
			data.push(item);
		});

		view.callEvent("onConditionSet", [data]);
	}
	_safeInt(a){
		var num = parseFloat(a);
		if (num == a) return num;
		return a;
	}
	_setCondition() {
		var view = this.view;
		var collection = view.conditions.get(this.cell.row, this.cell.column);

		if(collection){
			let forms = this.$dialog.getBody().getChildViews()[0].getChildViews()[0].getChildViews();
			collection.forEach((item, i) => {
				var values = {};
				values.condition = item[0];
				if(webix.isArray(item[1])){
					values.value = this._formatValue(item[1][0]);
					values.value2 = this._formatValue(item[1][1]);
				}
				else
					values.value = this._formatValue(item[1]);

				values.style = item[2];
				forms[i+1].setValues(values);
			});
		}
	}

	_formatValue(value){
		if(this.editFormat && value !== "" && value[0] != "="){
			value = getJsDateFromExcel(value);
			return this.editFormat(value);
		}
		return value.toString();
	}

	_normalizeValue(value){
		if(this.editFormat){
			if(isNaN(value)){
				if(value[0] != "="){
					const parser = webix.Date.strToDate( webix.i18n.spreadsheet.formats.parseDateTime );
					value = parser(value);
					value = isNaN(value) ? "" : getExcelDateFromJs(value);
				}
			}
			else
				value = isDateInvalid(value) ? "" : value;
		}
		else
			value = this._safeInt(value, 10);

		return value;
	}

	apply(){
		this._getCondition();
		this.close();
	}

	_clean() {
		let forms = this.$dialog.getBody().getChildViews()[0].getChildViews()[0].getChildViews();
		forms.forEach(form => {
			if (form.setValues) form.setValues({condition: "", value: "", value2: "", style: ""});
		});
	}

	$show(){
		this.cell = this.view.getSelectedId();
		if(!this.cell)
			return false;

		const item = this.view.getRow(this.cell.row);
		let type = item.$cellType;
		if(type)
			type = type[this.cell.column];

		if(type == "date")
			this.editFormat = getDateEditFormat(item, this.cell.column);

		this.$dialog.queryView("text", "all").forEach(view => {
			view.define({placeholder: type == "date" ? this._formatValue(45000) : ""});
			view.refresh();
		});

		this.view.$handleSelection = (st, end, a, b)=>{
			if (this.activeValue && a == b){
				this.activeValue.setValue("="+a);
			}
			return false;
		};
		this._setCondition();
	}

	$hide(){
		this.view.$handleSelection = null;
		delete this.editFormat;
		this._clean();
	}

	getRows(data, count){
		let headers = {
			height: 36,
			padding:{
				left: 12
			},
			cols: [
				{ view:"label", width:131, label: webix.i18n.spreadsheet.labels.display },
				{ view:"label", width: 116, label: webix.i18n.spreadsheet.labels.condition },
				{ view:"label", label: webix.i18n.spreadsheet.labels.value }
			]
		};
		let arr = [headers];

		let item = {
			view: "form",
			padding: 0,
			borderless: true,
			css:"webix_ssheet_cformats",
			elements: [{
				margin: 10,
				cols: [
					{
						view:"richselect",
						name: "style",
						width:120,
						placeholder: webix.i18n.spreadsheet.labels["conditional-style"],
						css: "webix_ssheet_cformat_select",
						suggest: {
							padding:0,
							borderless: true,
							css: "webix_ssheet_cformat_list",
							body: {
								template(obj) {
									var css = "webix_ssheet_cformat "+obj.css;
									return `<div class="${css}">${obj.name}</div>`;
								},
								data: data
							}
						}
					},
					{
						view:"richselect",
						width: 105,
						name: "condition",
						placeholder: webix.i18n.spreadsheet.labels["conditional-operator"],
						on: {
							onChange: function(newv){
								if(newv === "<>"){
									this.getFormView().elements.value2.show();
								}else {
									this.getFormView().elements.value2.hide();
								}
							}
						},
						suggest:{
							view: "ssheet-form-suggest",
							body:{
								data: condition
							}
						}
					},
					{
						cols: [
							{
								view: "text",
								on: {
									onFocus: (current_view)=>{
										this.activeValue = current_view;
									},
									onBlur: (current_view)=>{
										let value = current_view.getValue();
										if(value !== ""){
											value = this._normalizeValue(value);
											current_view.setValue(this._formatValue(value));
										}
									}
								},
								name: "value"
							},
							{
								view: "text",
								name: "value2",
								on: {
									onFocus: (current_view)=>{
										this.activeValue = current_view;
									},
									onBlur: (current_view)=>{
										let value = current_view.getValue();
										if(value !== ""){
											value = this._normalizeValue(value);
											current_view.setValue(this._formatValue(value));
										}
									}
								},
								hidden: true
							}
						]
					},
					{ view:"icon", css:"webix_ssheet_cformat_clear", icon:"wxi-trash", click:clearRow }
				]}
			]};

		while(count--){
			arr.push(item);
		}
		return arr;
	}

	$init(){
		//[IMPROVE] can be optimized by using datalayout instead of nested forms
		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["conditional-format"],
			position:"center",
			width: 670,
			move: true,
			buttons:false,
			body:{
				view: "form",
				borderless: true,
				rows: [
					{rows: this.getRows(getConditionStyle(this.view.config.conditionStyle), 3)},
					{
						cols:[
							{gravity:2},
							{
								view: "button",
								value: webix.i18n.spreadsheet.labels.apply,
								click: () => this.apply()
							}
						]
					}
				]
			},
			on:{
				onHideClick: () => this.close()
			}
		};
	}
}

function clearRow(){
	//this - clear icon
	var form = this.getFormView();
	form.clear();
}