import {getFormatName, getFormatSource} from "../operations/formats";

function isChar(char){
	var code = char.charCodeAt(0);
	// #(35) - errors like #REF!, $(36) - absolute cell reference, a-zA-Z(65-122) - chars
	return (code>=65 && code<=122) || code == 35 || code == 36;
}
function isNumber(char){
	var code = char.charCodeAt(0);
	return code>=48 && code<=57;
}

function getWord(formula, i, passive){
	var max = formula.length;
	var sheet = passive;
	var quotes = false;

	for(var j=i; j<max; j++){
		var key =  formula[j];
		if (key === "'"){
			quotes = !quotes;
			continue;
		}

		if (!quotes){
			if (key == "!"){
				if(formula[i] == "#") //error
					continue;
				else{
					//multi-sheet math
					sheet = formula.substr(i, j-i);
					if (sheet[0] === "'")
						sheet = sheet.substr(1, sheet.length-2);
					i=j+1;
				}
			}
			else if (!isChar(key) && !isNumber(key)){
				return [formula.substr(i, j-i), j, i, sheet];
			}
		}
	}

	return [formula.substr(i), j, i, sheet];
}

var operand = /^[A-Z$]+[0-9]+$/;
function isPosition(text){
	return operand.test(text);
}

export function position(word){
	var row = 0, sum = 0, x = 1, mode = 1, flags = 0;  
	var chars = false;

	for (var j =word.length-1; j>=0; j--){
		var key = word[j].charCodeAt(0);
		if (key === 36) {
			flags = flags+mode;
			continue;
		}

		if (key < 58){
			//numeric
			sum += (key - 48)*x;
			x *= 10;
		} else {
			if (!chars){
				x = 1; row = sum; sum = 0; chars = true; mode += 1;
			}
			//alpha
			sum += (key - 64)*x;
			x*=26;
		}
	}

	/*
		$X$Y => flags = 3
		$XY  => flags = 2
		X$Y  => flags = 1
		XY   => flags = 0
	*/
	return [row, sum, flags];
}

function errorCode(e){
	return `this.e("${e}")`;
}

function operandCode(deps, word, sheet){
	let [r,c] = position(word);

	if (sheet !== ""){
		deps.push([r,c,r,c,sheet]);
		return `this.vs("${sheet}",${r},${c})`;
	} else {
		deps.push([r,c,r,c,""]);
		return `this.v(${r},${c})`;
	}
}

function methodCode(word){
	return `this.m.${word}`;
}

function namedRangeCode(deps, view, word, sheet){
	var code  = view.ranges.getCode(word, sheet);
	if(!code) return "";

	var sheetInd = code.indexOf("!");
	if (sheetInd !== -1){
		sheet = code.substr(0, sheetInd);
		if (sheet[0] === "'")
			sheet = sheet.substr(1, sheet.length-2);
		code = code.substr(sheetInd+1);
	}

	let [a,b] = code.split(":");
	return rangeCode(deps, a, b, sheet);
}

function templateCode(word){
	return `this.p.${word}`;
}

function rangeCode(deps, a, b, sheet){
	let [r1,c1] = position(a);
	let [r2,c2] = position(b);

	if (r1>r2) { let t=r1; r1=r2; r2=t; }
	if (c1>c2) { let t=c1; c1=c2; c2=t; }
	
	if (sheet === ""){
		deps.push([r1,c1,r2,c2,""]);
		return `this.r(${r1},${c1},${r2},${c2})`;
	} else {
		deps.push([r1,c1,r2,c2, sheet]);
		return `this.rs("${sheet}",${r1},${c1},${r2},${c2})`;
	}
}

export function split(formula, crosssheet, ranges){
	var lines = [];
	var index = 0;
	var quotes = false, ph = false;
	for (var i = formula[i] === "=" ? 1 : 0; i < formula.length; i++){
		var key = formula[i];
		if (key == "\""){
			quotes = !quotes;
		} else if (!quotes){
			if (key == "{" && formula[i+1] == "{"){
				ph = true;
			} else if (key == "}" && formula[i+1] == "}"){
				ph = false;
			} else if (!ph){
				const escaped = key === "'";
				if (escaped || isChar(key)){
					let [word, end, ,sheet] = getWord(formula, i, "");
					let next = end - 1;
					let value;
					if (!sheet || (!crosssheet && sheet)){
						if(sheet){
							// 'sheet'! - 3 extra symbols, sheet! - 1 extra symbol
							i += sheet.length + (escaped ? 3 : 1);
						}
						if (formula[next+1] !== "(" && (isPosition(word) || (ranges && ranges[word]))){
							value = crosssheet ? word : position(word);
							pushLine(lines, formula, i, index, value);
							index = next+1;
						}
					}
					else if(crosssheet){
						value = [sheet, word, escaped];
						pushLine(lines, formula, i, index, value);
						index = next+1;
					}
					i = next;
				}
			}
		}
	}

	if (index!=formula.length)
		lines.push(formula.substr(index));
	return lines;
}

function pushLine(lines, formula, i, index, value){
	if (i!==0)
		lines.push(formula.substr(index, i-index));
	lines.push(value);
}

export function parse(formula, view, passive = ""){
	var code ="return ";
	var deps = [];

	var quotes = false;
	var pair = "", pairsheet = "";

	if (formula[0] != "=") return false;

	for (var i = 1; i < formula.length; i++){
		var key = formula[i];

		if (key == "\"")
			quotes = !quotes;
		else if (key == "{" && formula[i+1] == "{"){
			let [word, end] = getWord(formula, i+2);
			i = end + 1;
			code += templateCode(word);
			continue;
		}
		else if (!quotes && (key == "'" || isChar(key))){
			let [rawword, end, start, sheet] = getWord(formula, i, passive);

			//lower case formulas, fix them
			let word = rawword.toUpperCase();

			i = end - 1;

			//errors like #REF!
			if(word[0] == "#"){
				code += errorCode(word);
				break;
			}
			else if (formula[i+1] == "("){
				code += methodCode(word);
			}
			else if (isPosition(word)){
				if (formula[i+1] == ":"){
					pair = word; pairsheet = sheet;
					i++;
				} else {
					if (pair !== ""){
						code += rangeCode(deps, pair, word, pairsheet);
						pair = "";
					} else 
						code += operandCode(deps, word, sheet);
				}
			}
			else {
				let range = namedRangeCode(deps, view, word, sheet);
				//we have some error word in a formula
				//break from loop to skip formula updating
				if (range == ""){
					code += errorCode("#REF!");
					break;
				}
				code += range;
			}

			//operator, or range, or parameter
			if (word !== rawword){
				formula = formula.substr(0, start)+word+formula.substr(end);
			}

			continue;
		}

		if (!quotes){
			//special handling for & operator, string concat
			if (key === "&" && formula[i+1] !== "&")
				code += "+";
			//convert <> to !=
			else if (key === "<" && formula[i+1] === ">"){
				code += "!=";
				i++;
			//convert = to ==
			} else if (key === "=" && formula[i-1] !== "<" && formula[i-1] !== ">")
				code += "==";
			else
				code += key;
		} else
			code += key;
	}


	return { code: code+";", triggers:deps, text: formula };
}

export function getMathFormat(view, value){
	let level = 0;
	let blockLevel;
	const ranges = view.ranges._ranges;
	const parts = split(value, true, ranges);

	for(let i = 0; i < parts.length; i++){
		// parts[i] can contain numbers, math operators, formulas, etc.
		if(i%2 === 0){
			// check formulas and round brackets which are not in quotes
			const methods = parts[i].match(/(([a-zA-Z]*\()|\))(?=(?:[^"]|"[^"]*")*$)/g);
			if(methods)
				methods.forEach(part => {
					if(part.indexOf("(") > -1){
						level++;
						// some methods block format addition (like count,odd,etc.)
						const safeMethods = /^(SUM|AVERAGE|MAX|MIN|INT|ROUND|ROUNDDOWN|ROUNDUP|TRUNC)?\($/gi;
						if(!blockLevel && !safeMethods.test(part))
							blockLevel = level;
					}
					else if(--level < blockLevel)
						blockLevel = null;
				});
		}
		// parts[i] can be named range, cell from the active / non-active sheet
		else if(!blockLevel){
			if(webix.isArray(parts[i])){
				if(parts[i][0] == view.getActiveSheet())
					parts[i] = parts[i][1];
				else
					continue; // ignore non-active sheets
			}

			// if range - check first cell position
			const cell = /^[A-Za-z]+$/.test(parts[i]) ? position(ranges[parts[i]].split(":")[0]) : position(parts[i]);
			const name = getFormatName(view, cell[0], cell[1]);

			// first cell with number format
			if(name)
				return {fmt: getFormatSource(name)};
		}
	}
}