import {getFormatName, checkFormat, isDateInvalid} from "./formats";
import {getExcelDateFromJs} from "../math/methods/helpers";
import {getMathFormat} from "../math/parser";

export function init(view){
	view.attachEvent("onCellChange", function(row, col, value, type){
		if(!this._setType){
			const format = getFormatName(this, row, col);
			setType(this, row, col, format, type);
		}
	});

	view.attachEvent("onBeforeFormatChange", function(row, col, format){
		if(!this._setType)
			setType(this, row, col, format, null, true);
	});

	view.attachEvent("onAction", function(action, values){
		if(action == "dropdown" && values.newValue && values.newValue.editor == "excel_date"){
			const format = getFormatName(this, values.row, values.column);
			if(getFormatType(format) != "date")
				this.setFormat(values.row, values.column, webix.i18n.spreadsheet.formats.dateFormat);
		}
	});
}

function getExpectType(view, row, col, value, parseDate, isFormula, formatType, style){
	//date based math
	let type = "string";
	if(!webix.isUndefined(value)){
		if(isFormula){
			type = "number";

			if(/^=(now|date|time)\(/i.test(value))
				type = "date";
		}
		else if(!isNaN(value))
			type = "number";
		else if(formatType != "string" && parseDate){
			//if user types a date string, or it comes from Excel import
			//set date type and convert string to Excel date
			const parser = webix.Date.strToDate( webix.i18n.spreadsheet.formats.parseDateTime );
			value  = parser(value);
			if(value != "" && !isNaN(value)){
				//change cell style - value changes once (some value -> 35767.5)
				//change cell value - value changes twice (some value -> "12/03/97" -> 35767.5)
				//so ignore undo for cell value change
				if(style)
					dateToExcel(view, row, col, value);
				else
					view.ignoreUndo(() => dateToExcel(view, row, col, value));

				type = "date";
			}
		}
	}
	return type;
}

function dateToExcel(view, row, col, value){
	value = getExcelDateFromJs(value);
	view.setCellValue(row, col, value);
}

function checkType(type, expectType, formatType, isFormula, value){
	type = type || expectType;

	//strings will always have string type regardless of format
	if(expectType == "string")
		type = "string";
	else if(formatType)
		type = formatType;

	if(type == "date" && !isFormula && isDateInvalid(value))
		type = "number";

	return type;
}

function getFormatType(format){
	let formatType = format ? "number" : null;
	if(checkFormat(format, "string"))
		formatType = "string";
	else if(checkFormat(format, "date"))
		formatType = "date";
	return formatType;
}

export function getType(view, row, column){
	const types = view.getRow(row).$cellType;
	if (types && types[column])
		return types[column];
}

function setType(view, row, col, format, type, style){
	//prevent type change during value or format change
	view._setType = true;
	let value = view.getCellValue(row, col);

	const isFormula = typeof value == "string" && value[0] == "=";
	const parseDate = (!style || format == "date") && (!view._loading_data || type == "date");

	const mathFormat = isFormula && !format ? getMathFormat(view, value) : null;

	const formatType = getFormatType(format || mathFormat);
	const expectType = getExpectType(view, row, col, value, parseDate, isFormula, formatType, style);

	type = checkType(type, expectType, formatType, isFormula, value);
	updateCell(view, row, col, type, expectType, formatType, mathFormat);

	const item = view.getRow(row);
	if(!item.$cellType)
		item.$cellType = {};
	item.$cellType[col] = type;

	delete view._setType;
}



function updateCell(view, row, col, type, expectType, formatType, mathFormat){
	let value = view.getCellValue(row, col);
	const isFormula = typeof value == "string" && value[0] == "=";
	const cellEditor = view.getCellEditor(row, col);

	if(mathFormat)
		view.setFormat(row, col, mathFormat.fmt);
	else if(type == "date"){
		// add default date format
		if(formatType != "date"){
			const isTime = isFormula && value.toUpperCase().indexOf("=TIME(") == 0;
			const locale = webix.i18n.spreadsheet.formats;
			const format = isTime ? locale.timeFormat : locale.dateFormat;
			view.setFormat(row, col, format);
		}

		//set date editor for cells with date type
		if(!isFormula && !cellEditor)
			view.setCellEditor(row, col, {editor: "excel_date"});
	}
	else if(formatType != "string" && type == "string" && expectType != "string"){
		//force text format for numbers typed as strings
		view.setFormat(row, col, "@");
	}

	//remove date editor
	if((type != "date" || isFormula) && cellEditor && cellEditor.editor == "excel_date")
		view.setCellEditor(row, col, null);
}
