export default {
	labels:{
		// formats
		"common": "Common",
		"currency": "Currency",
		"number": "Number",
		"percent": "Percent",
		"date": "Date",
		"string": "Text",
		"custom-format": "Custom",
		"decimal-places":"Decimal places",
		"separator":"Group separator",
		"negative":"Negative number",
		"date-format":"Date format",
		"format-docs":"How to create a format",
		// titles
		"undo-redo": "Undo/Redo",
		"font": "Font",
		"text": "Text",
		"cell": "Cell",
		"align": "Align",
		"format": "Number",
		"column": "Column",
		"borders": "Borders",
		"px": "px",
		"apply": "Apply",
		// popups and dialogs
		"cancel": "Cancel",
		"save": "Save",
		// multisheets
		"sheet": "Sheet",
		// conditions
		"conditional-format": "Conditional Format",
		"condition": "Condition",
		"conditional-style": "Style",
		"conditional-operator": "Operator",
		"conditional-between":"between",
		"conditional-not-equal":"not equal",
		// ranges
		"range": "Range",
		"range-title": "Named ranges",
		"range-name": "Name",
		"range-cells": "Range",
		// images
		"image-or": "or",
		"image-title": "Add image",
		"image-upload": "Select file for upload",
		"image-url": "URL (e.g. http://*)",
		// sparklines
		"sparkline-title": "Add sparkline",
		"sparkline-type": "Type",
		"sparkline-range": "Range",
		"sparkline-color": "Color",
		"sparkline-positive": "Positive",
		"sparkline-negative": "Negative",
		// custom format
		"format-title": "Set format",
		"format-pattern": "Format pattern",
		//dropdown
		"dropdown-empty": "Empty cells",
		"dropdown-title": "Add dropdown",
		"dropdown-range": "Range",
		// confirm
		"ok": "OK",
		// import/export
		"import-title": "Import",
		"import-not-support": "Sorry, your browser does not support import",
		"export-title": "Export",
		"export-name": "Name of xslx file",
		"export-all-sheets":"Export all sheets",
		// add link
		"link-title": "Add Link",
		"link-name": "Text",
		"link-url": "URL",
		//images
		"image": "Image",
		"add-image-cell":"Add to cell",
		"add-image-top":"Add above cells",
		//charts
		"graph": "Graph",
		"add-sparkline":"Add to cell",
		"add-chart":"Add above cells",
		//conditional format labels
		"display" : "Display",
		"value" : "Value",
		// confirm messages
		"range-remove-confirm": "Are you sure you want to remove the range permanently?",
		"sheet-remove-confirm": "Are you sure you want to remove the sheet permanently?",
		"merge-cell-confirm":"Only the left top value will remain after merging. Continue?",
		"error-range": "The range is incorrect!",
		//print
		"print":"Print",
		"print-title":"Before you print..",
		"print-settings":"General settings",
		"print-paper":"Paper size",
		"print-layout":"Layout",
		"current-sheet":"Current sheet",
		"all-sheets":"All sheets",
		"selection":"Selected cells",
		"borderless":"Hide gridlines",
		"sheet-names":"Show sheet names",
		"skip-rows":"Skip empty rows",
		"margin":"Hide margins",
		"page-letter":"Letter",
		"page-a4":"A4 (210x297mm)",
		"page-a3":"A3 (297x420mm)",
		"page-width":"Page width",
		"page-actual":"Actual Size",
		"page-portrait":"Portrait",
		"page-landscape":"Landscape",
		//comment
		"comment":"Comment",
		//resize
		"width":"Width",
		"height":"Height",
		"fit-content": "Fit to content",
		//views
		"edit-view":"Edit",
		"remove-view":"Remove",
		"chart-edit":"Edit chart",

		"chart-common":"Common",
		"chart-series":"Series",
		"chart-extras":"Extras",

		"export-view":"Download",
		"export-view-pdf":"PDF document",
		"export-view-png":"PNG image",
		"export-view-excel":"Excel file",

		"line-chart":"Line",
		"area-chart":"Area",
		"bar-chart":"Bar",
		"donut-chart":"Donut",
		"pie-chart":"Pie",
		"radar-chart":"Radar",
		"spline-chart":"Spline",
		"splinearea-chart":"Spline Area",

		"chart-axis":"Axis",
		"chart-scale":"Lines",
		"chart-scale-color":"Scale color",
		"chart-xAxis-title":"X axis title",
		"chart-yAxis-title":"Y axis title",
		"chart-col-xAxis":"Use left column as xAxis",
		"chart-row-xAxis":"Use top row as xAxis",
		"chart-xAxis-range":"xAxis range",

		"chart-legend":"Legend",
		"chart-row-legend":"Use top row as legend",
		"chart-col-legend":"Use left column as legend",
		"chart-legend-range":"Legend range",
		"chart-legend-align":"Align",
		"chart-legend-left":"Left",
		"chart-legend-right":"Right",
		"chart-legend-center":"Center",
		"chart-legend-valign":"Vertical align",
		"chart-legend-top":"Top",
		"chart-legend-middle":"Middle",
		"chart-legend-bottom":"Bottom",

		"chart-item":"Data",
		"stacked-chart":"Stacked",
		"chart-range":"Range",
		"chart-series-color":"Color",
		"add-line":"Add line",
		"chart-type":"Type",
		"chart-tooltip":"Tooltips",

		"chart-data-from":"Data series from",
		"chart-columns":"columns",
		"chart-rows":"rows",

		"chart-markers":"Markers",
		"chart-markers-square":"Square",
		"chart-markers-triangle":"Triangle",
		"chart-markers-diamond":"Diamond",
		"chart-markers-round":"Round",

		"chart-pie-text":"Show values inside",
		"chart-pie-3d":"3D",

		"chart-radar-circle":"Circled lines"
	},
	tooltips: {
		"color": "Font color",
		"background": "Background color",
		"font-family": "Font family",
		"font-size": "Font size",
		"text-align": "Horizontal align",
		"vertical-align": "Vertical align",
		"borders": "Borders",
		"borders-no": "Clear borders",
		"borders-left": "Left border",
		"borders-top": "Top border",
		"borders-right": "Right border",
		"borders-bottom": "Bottom border",
		"borders-all": "All borders",
		"borders-outer": "Outer borders",
		"borders-top-bottom": "Top and bottom borders",
		"borders-color": "Border color",
		"align-left": "Left align",
		"align-center": "Center align",
		"align-right": "Right align",
		"align-top": "Top align",
		"align-middle": "Middle align",
		"align-bottom": "Bottom align",
		"span": "Merge",
		"wrap": "Text wrap",
		"undo": "Undo",
		"redo": "Redo",
		"format": "Number format",
		"increase-decimals":"Increase decimal places",
		"decrease-decimals":"Decrease decimal places",
		"font-weight": "Bold",
		"font-style": "Italic",
		"text-decoration": "Underline",
		"hide-gridlines": "Hide/show gridlines",
		"hide-headers": "Hide/show headers",
		"create-filter": "Create/remove filters",
		"freeze-columns" : "Freeze/unfreeze columns",
		"add-range": "Set name for the selected range",
		"conditional": "Conditional formatting",
		"add-sheet": "Add Sheet",
		"lock-cell": "Lock/unlock cell",
		"clear": "Clear",
		"add-link": "Add link",
		"row": "Rows",
		"column": "Columns",
		"sheet": "Sheet",
		"add-image": "Image",
		"add-sparkline": "Graph",
		"add-comment": "Comment"
	},
	menus:{
		"remove-sheet": "Remove sheet",
		"rename-sheet": "Rename sheet",
		"file": "File",
		"new": "New",
		"new-sheet": "New sheet",
		"excel-import": "Import from Excel",
		"excel-export": "Export to Excel",
		"sheet": "Sheets",
		"copy-sheet": "Copy to new sheet",
		"edit": "Edit",
		"undo": "Undo",
		"redo": "Redo",
		"columns": "Columns",
		"insert-column": "Insert column",
		"delete-column": "Delete column",
		"show-column": "Show column",
		"hide-column": "Hide column",
		"resize-column": "Resize column",
		"rows": "Rows",
		"insert-row": "Insert row",
		"delete-row": "Delete row",
		"show-row": "Show row",
		"hide-row": "Hide row",
		"resize-row": "Resize row",
		"insert": "Insert",
		"conditional-format": "Conditional format",
		"clear": "Clear",
		"clear-value":"Values",
		"clear-style":"Styles",
		"clear-conditional-formats":"Conditional formats",
		"clear-dropdown-editors":"Dropdowns and filters",
		"clear-comments":"Comments",
		"clear-all":"All",
		"image": "Image",
		"add-image-cell":"Add to cell",
		"add-image-top":"Add above cells",
		"graph": "Graph",
		"add-sparkline":"Add to cell",
		"add-chart":"Add above cells",
		"data": "Data",
		"add-link": "Add link",
		"add-range": "Named ranges",
		"sort": "Sort",
		"sort-asc": "Sort A to Z",
		"sort-desc": "Sort Z to A",
		"view": "View",
		"freeze-columns": "Freeze/unfreeze columns",
		"freeze-rows": "Freeze/unfreeze rows",
		"hide-gridlines": "Hide/show gridlines",
		"hide-headers": "Hide/show headers",
		"create-filter": "Create/remove filters",
		"add-dropdown": "Add dropdown",
		"lock-cell": "Lock/unlock cell",
		"print": "Print",
		"add-comment":"Comment"
	},
	table:{
		"math-error":"ERROR",
		"format-error":"INCORRECT FORMAT"
	},
	liveEditor:{
		"edit":"Edit:"
	},
	formats:{
		"dateFormat": "mm/dd/yyyy",
		"timeFormat": "hh:mm AM/PM",
		"longDateFormat": "dd mmmm yyyy",
		"fullDateFormat": "mm/dd/yyyy hh:mm AM/PM",
		"parseDateTime": "%m/%d/%Y %G:%i:%s",
		"parseDate": "%m/%d/%Y"
	}
};
