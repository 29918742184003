export const decode = {};
export const encode = {};

const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
for (var i=1; i<1000; i++){
	var prefixIndex = parseInt((i-1)/alpha.length);
	var str = (prefixIndex?alpha[prefixIndex-1]:"")+alpha[(i-1)%(alpha.length)];

	decode[str] = i;
	encode[i] = str;
}

encode[0]=encode[1];


export function adress(text){
	var size = (text.charCodeAt(1) < 59) ? 1 : 2;
	var col = text.substr(0, size);
	var row = text.substr(size);

	return [row*1, decode[col]];
}

export function range(text, view){
	var nind = text.indexOf("!");
	var sheet = "";

	//parse sheet names
	if (nind !== -1){
		sheet = text.substr(0, nind);
		if (sheet[0] === "'")
			sheet = sheet.substr(1, sheet.length-2);
		text = text.substr(nind+1);
	}

	var parts = text.split(":");
	if (parts.length != 2 && view){
		text = view.ranges.getCode(text, sheet);
		if (!text) return null;
		parts = text.split(":");
	} 

	var d1 = adress(parts[0]);
	var d2 = adress(parts[1]);
	return [d1[0], d1[1], d2[0], d2[1], sheet];
}


var notAlphaNum = /[^A-Za-z0-9]/;
function escapeSheet(text){
	if (notAlphaNum.test(text))
		return `'${text}'`;
	return text;
}

export function toRange(x,y,x2,y2,sheet){
	return (sheet?(escapeSheet(sheet)+"!"):"")+encode[y]+x+":"+encode[y2]+x2;
}
export function toSheetRange(x,y,sheet){
	return (sheet?(escapeSheet(sheet)+"!"):"")+x+":"+y;
}

export function rangeObj(text, view){
	if (typeof text === "object") return text;
	let a = range(text, view);
	return { start:{ row:a[0], column:a[1]}, end:{ row:a[2], column:a[3] }, sheet: a[4] };
}

export function eachRange(text, view, callback, data){
	var range = rangeObj(text);
	for (let row = range.start.row; row <= range.end.row; row++) {
		for (let column = range.start.column; column <=  range.end.column; column++) {
			callback(view, {row: row, column: column}, data);
		}
	}
}

var rangeTest = /(([A-Za-z0-9]+|'[^']+')!|)[A-Z]+[0-9]+:[A-Z]+[0-9]+/;
export function isRange(text){
	return rangeTest.test(text);
}

export function changeRange(text,name,inc,start){
	let updated = false;

	if(isRange(text)){
		let [r1,c1,r2,c2,sheet] = range(text);

		//ranges like B2:A1
		if(r1 > r2)
			[r1, r2] = [r2, r1];
		if(c1 > c2)
			[c1, c2] = [c2, c1];

		if (name === "row" && start.row <= r2){
			if((inc < 0 && start.row < r1) || (inc > 0 && start.row <= r1))
				r1 += inc;
			r2 += inc;

			if(r2<r1)
				return "";

			updated = true;
		}
		else if (name === "column" && start.column <= c2){
			if((inc < 0 && start.column < c1) || (inc > 0 && start.column <= c1))
				c1 += inc;
			c2 += inc;

			if(c2<c1)
				return "";

			updated = true;
		}
		if(updated)
			text = toRange(r1,c1,r2,c2,sheet);
	}

	return text;
}