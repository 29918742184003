webix.protoUI({
	name: "suggest-formula",
	defaults: {
		fitMaster: false,
		width: 200,
		filter(item, value) {
			const editor = webix.$$(this.config.master);
			const cursor = editor.getInputNode().selectionStart;
			const val = editor.getValue();

			if(val.charAt(0) !== "=") return;

			let str1 = val.substring(0, cursor).match(/([a-zA-Z]+)$/);
			let str2 = val.charAt(cursor).search(/[^A-Za-z0-9]/);

			if(str1 && (cursor === val.length || str2 === 0))
				value = str1[0];

			return item.value.toString().toLowerCase().indexOf(value.toLowerCase()) === 0;
		}
	},
	$init() {
		this.attachEvent("onBeforeShow", (node) => {
			if(node.tagName){
				const editor = webix.$$(this.config.master);
				const text = editor.getValue();

				//only for formulas
				if(!text || text.charAt(0) !== "=") return false;

				const sizes = webix.html.offset(node);

				const selectionStart = editor.getInputNode().selectionStart;
				const textWidth = webix.html.getTextSize(text.substring(0, selectionStart), "webix_ssheet_formula").width;

				const y = sizes.y + sizes.height;
				const x = sizes.x + textWidth;

				webix.ui.popup.prototype.show.apply(this, [{x, y}]);
				return false;
			}
		});
	},
	setMasterValue(data, refresh) {
		const text = data.id ? this.getItemText(data.id) : (data.text||data.value);
		webix.$$(this.config.master).setValueHere(text);

		if (!refresh)
			this.hide(true);

		this.callEvent("onValueSuggest", [data, text]);
	},
	$enterKey: function() {
		if (this.isVisible())
			return webix.ui.suggest.prototype.$enterKey.apply(this, arguments);
	}
}, webix.ui.suggest);