import {getJsDateFromExcel, getExcelDateFromJs} from "../math/methods/helpers";

function getJsDate(value){
	if ((value || value === 0) && !(value instanceof Date) && !isNaN(value)){
		value = getJsDateFromExcel(value);
	}
	return value;
}

function getExcelDate(value){
	if (value && value instanceof Date){
		value = getExcelDateFromJs(value);
	}
	return value;
}

webix.protoUI({
	$cssName: "datepicker",
	name: "ssheet-datepicker",
	getValue:function(){
		const value = webix.ui.datepicker.prototype.getValue.apply(this);
		return getExcelDate(value) || "";
	},
	$prepareValue:function(value){
		value = getJsDate(value);
		return webix.ui.datepicker.prototype.$prepareValue.apply(this, [value]);
	}
}, webix.ui.datepicker);

webix.protoUI({
	$cssName: "daterangepicker",
	name: "ssheet-daterangepicker",
	getValue:function(){
		let value = webix.ui.datepicker.prototype.getValue.apply(this);
		if (value){
			value = webix.copy(value);
			value.start = getExcelDate(value.start);
			value.end = getExcelDate(value.end);
		}
		return value || "";
	},
	$prepareValue:function(value){
		if (!value){
			value = { start:null, end:null };
		}
		else if (!value.start && !value.end)
			value = { start:value };

		value.start = getJsDate(value.start);
		value.end = getJsDate(value.end);

		return webix.ui.daterangepicker.prototype.$prepareValue.apply(this, [value]);
	}
}, webix.ui.daterangepicker);