import {range} from "../helpers/column_names";
import {getAccessor} from "./sheet";
import {cell_template} from "../table";
import {clearMathCache} from "./cache";

function nullFilter(){ 
	const locale = webix.i18n.spreadsheet.labels;
	return { id:"$empty", value:"", $value:"<span class='webix_ssheet_empty'>"+locale["dropdown-empty"]+"</span>" };
}

function getRange(view, r1,c1,r2,c2,sheet){
	const values = [];
	for (let i=r1; i<=r2; i++){
		const item = this.getItem(i);
		for (let j=c1; j<=c2; j++){
			let value = (item[j] || item[j] === 0) ? item[j] : "";
			value = { id:value, value:value };

			if (!sheet || sheet == view._activeSheet){
				value["$value"] = cell_template(view, item, null, item[j], {id:j}, true);
				value["format"] = item.$cellFormat && item.$cellFormat[j];
			}
			values.push(value);
		}
	}
	return values;
}

export function rangeOptions(view, text, extra){
	let values = [];
	if (typeof text == "string"){
		const pos = range(text, view);
		if (pos){
			const page = getAccessor(view, pos[4]);
			values = page ? getRange.apply(page, [view, ...pos]) : [];
		}
	}
	else if (webix.isArray(text))
		for (let i=0; i<text.length; i++){
			let v = (text[i] || text[i] === 0) ? text[i] : "";
			v = (typeof v === "object") ? webix.copy(v) : { id:v, value:v };
			values.push(v);
		}

	if (extra){
		if (extra.unique){
			const test = {};
			for (let i=values.length-1; i>=0; i--){
				if (test[values[i].id])
					values.splice(i, 1);
				else
					test[values[i].id] = true;
			}
		}

		let empty = false;
		for (let i=values.length-1; i>=0; i--){
			values[i].value = String(values[i].value);
			if (values[i].value === ""){
				values.splice(i, 1);
				empty = true;
			}
		}

		if (extra.order)
			values.sort(function(objA, objB){
				const sorters = view.$$("cells").data.sorting.as;
				const a = objA.value;
				const b = objB.value;

				return webix.rules.isNumber(a) && webix.rules.isNumber(b) ? sorters.int(a, b) : sorters.string(a, b);
			});

		if (empty && extra.filter)
			values.unshift( nullFilter() );

		if (extra.empty)
			values.unshift({ id:"$empty", $empty:true, value:"" });
	}
	return values;
}

export function rangeValues(view, text){
	let values = [];
	if (typeof text == "string"){
		const pos = range(text, view);
		if (pos){
			const page = getAccessor(view, pos[4]);
			values = page ? page.getRange.apply(page, pos) : [];
		}
	} else
		values = webix.copy(text);

	return values;
}

export class Ranges{
	constructor(view){
		this._master = view;
		this._ranges = {};
	}
	clear(){
		this._ranges = {};
	}
	add(name, text){
		this._master.callEvent("onAfterRangeSet", [name, text]);
		this._ranges[name] = text;

		clearMathCache(true);
		this._master.callEvent("onMathRefresh", []);
	}
	getCode(name, sheet){
		if (sheet){
			const accessor = getAccessor(this._master, sheet);
			return accessor ? accessor.getRangeCode(name) : null;
		}

		return this._ranges[name];
	}
	remove(name){
		delete this._ranges[name];

		clearMathCache(true);
		this._master.callEvent("onMathRefresh", []);
	}
	getRanges(){
		var data = [];
		for(var name in this._ranges)
			data.push({ name, range:this._ranges[name] });

		return data;
	}
	parse(data){
		if (!data) return;

		let i = data.length;
		while(i--){
			var c = data[i];
			this._ranges[c[0]] = c[1];
		}
	}
	serialize(){
		var name, data = [];
		for( name in this._ranges)
			data.push([name,this._ranges[name]]);
		return data;
	}
}
