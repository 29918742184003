import {actions} from "./toolbar/actions";
import {defaultStyles} from "./toolbar/defaults";
import {toolbarButtons, getButtons} from "./toolbar/helpers";
import * as elm from "./toolbar/all_elements";
import {isCustom} from "./operations/formats";

const buttons = {
	"undo-redo": ["undo","redo"],
	"font": ["font-family","font-size","font-weight","font-style","text-decoration","color","background","borders"],
	"align": ["text-align","vertical-align","wrap","span"],
	"format": ["format"]
};


export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));
	view.attachEvent("onCommand", (action) => {
		if (action.id == "toolbar-update")
			updateValue(view, action.name, action.value);
	});

	var elements = [];

	if(view.config.toolbar){
		let allElements =  view.config.toolbar;
		if(allElements == "full"){
			allElements = elm.getAllElements();
			if(webix.isUndefined(view.config.bottombar))
				view.config.bottombar = true;
		}
		elements =   toolbarButtons(view, allElements);
	}
	else
		elements =   getButtons(view, view.config.buttons || buttons);

	var bar = {
		view:"toolbar",
		css: "webix_ssheet_toolbar webix_layout_toolbar",
		id:"bar",
		padding:0,
		elements: elements,
		on: {
			onChange: function (){
				var source = this.$eventSource;
				var value = source.getValue();
				var name = source.config.name;
				if(name == "format" && value == "common")
					value = "";
				if(!(name == "format" && value == "custom"))
					view.callEvent("onStyleSet", [name, value]);
			},
			onItemClick: function (id){
				var viewId = view.innerId(id);
				if (actions[viewId])
					actions[viewId].call(this, view);
				else
					view.callEvent("onCommand", [{ id: viewId }]);
			}
		}
	};

	view.callEvent("onViewInit", ["toolbar", bar]);
	return bar;

}

function ready(view){
	view.attachEvent("onAfterSelect", (selected) => setValues(view, selected));
}

function setValues(view, selected) {
	const barStyles = getBarStyles(view, selected);
	view.$$("bar").setValues(barStyles);
}

function updateValue(view, name, value){
	const element = view.$$("bar").elements[name];

	if (element)
		element.setValue(value);
}

function getBarStyles(view, selected){
	const dropdowns = [
		"font-family",
		"font-size",
		"text-align",
		"vertical-align",
		"format",
		"background",
		"color"
	];

	const cell = selected[0];
	const barStyles = getCellStyles(view, cell);

	for(let i = 1; i < selected.length; i++){
		const cellStyle = getCellStyles(view, selected[i]);

		for(let k = dropdowns.length-1; k >= 0; k--){
			const property = dropdowns[k];
			if(barStyles[property] != cellStyle[property]){
				delete barStyles[property];
				dropdowns.splice(k, 1);
			}
		}

		if(!dropdowns.length)
			break;
	}

	return barStyles;
}

function getCellStyles(view, cell){
	const span = view.$$("cells").getSpan(cell.row, cell.column);
	if(span)
		[cell.row, cell.column] = span;

	const obj = view.getStyle(cell.row, cell.column);
	const styles = obj ? webix.copy(obj.props) : {};

	for (let i in defaultStyles)
		if(!styles[i])
			styles[i] = defaultStyles[i];

	styles.format = isCustom(styles.format) ? "custom" : styles.format;

	return styles;
}