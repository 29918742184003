webix.protoUI({
	name: "ssheet-ui",
	defaults:{
		move:true,
		css:"webix_shadow_none webix_ssheet_ui",
		head:false,
		resize:true,
		toFront:true,
		minWidth:50,
		minHeight:200,
		escHide:false,
		autofit:false
	},
	$init:function(){
		// prevent block selection
		this.$view.setAttribute("webixignore", "1");

		this.$ready.push(function(){
			webix.UIManager.addHotKey("delete", webix.bind(this._removeView, this));
			webix.UIManager.addHotKey("backspace", webix.bind(this._removeView, this));
			this.getMenu();
		});
		this.attachEvent("onDestruct", function(){
			webix.eventRemove(this._clickEv);
			this._menu.destructor();
		});
	},
	move_setter:function(value){
		if (value){
			webix.DragControl.addDrag(this.$view, this);
		}
		return value;
	},
	$dragPos:function(pos, e){
		this.callEvent("onViewMove", [pos, e]);
		return pos;
	},
	$dragCreate:function(object, e){
		const trg = e.target;
		if (!trg.getAttribute || trg.getAttribute("webix_disable_drag") || trg.getAttribute("webixignore"))
			return;

		this.getMenu().hide();
		const elOffset = webix.html.offset(object);
		const elPos = webix.html.pos(e);
		const pOffset = webix.html.offset(object.parentNode);
		const state = webix.$$(this.config.master).getScrollState();

		webix.DragControl.top = elOffset.y - elPos.y - pOffset.y;
		webix.DragControl.left = elOffset.x - elPos.x - pOffset.x + state.x;

		return webix.toNode(this.$view);
	},
	$dragDestroy:function(node, drag){
		const x = this.config.left = parseInt(drag.style.left,10);
		const y = this.config.top = parseInt(drag.style.top,10);

		webix.DragControl.top = webix.DragControl.left = 0;

		this.callEvent("onViewMoveEnd", [x, y]);
		return;
	},
	getMenu:function(){
		const labels = webix.i18n.spreadsheet.labels;
		if(!this._menu){
			let data = [
				{ value:labels["export-view"], submenu:[
					{ id:"pdf", value:labels["export-view-pdf"] },
					{ id:"png", value:labels["export-view-png"] },
					{ id:"excel", value:labels["export-view-excel"] }
				] }
			];

			if(!webix.$$(this.config.master).getTopParentView().config.readonly)
				data = [
					{ id:"edit", value:labels["edit-view"] },
					...data,
					{ id:"del", value:labels["remove-view"] }
				];

			this._menu = webix.ui({
				view:"contextmenu",
				data:data,
				on:{
					onMenuItemClick: id => this._menuActions(id),
					onBeforeShow: () => this._toggleExcel()
				}
			});
			const dots = webix.html.create("span", {"class":"webix_ssheet_view_menu webix_icon wxi-dots"});
			this._clickEv = webix.event(dots, "click", ()=> {
				if(this._menu.isVisible())
					this._menu.hide();
				else
					this._menu.show(dots);
			});
			this.$view.firstChild.appendChild(dots);
		}
		return this._menu;
	},
	_menuActions:function(id){
		switch(id){
			case "del":
				this.callEvent("onViewRemove", []);
				break;
			case "edit":
				this.callEvent("onViewEdit", []);
				break;
			case "pdf":
				webix.toPDF(this.getBody(), {display:"image"});
				break;
			case "png":
				webix.toPNG(this.getBody());
				break;
			case "excel":
				webix.toExcel(this.getBody(), {header: false});
				break;
		}
	},
	_toggleExcel:function(){
		const view = this.getBody();
		if(view.data && view.data.pull)
			this._menu.showItem("excel");
		else
			this._menu.hideItem("excel");
	},
	_removeView:function(view){
		if(view && view.getTopParentView() == this)
			this.callEvent("onViewRemove", []);
	}
}, webix.ui.window);