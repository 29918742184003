//TODO
// ### column deleting | adding
// recalculate positions

// ### copy paste between sheets
// force sheet name

// ### triggers
// a <- p <- a
// register triggers from passive sheet

// ### sheet renaming
// adjust math on sheet rename
// adjust ranges on sheet rename

import {getActive, getSheet} from "../sheets";
import {range, encode} from "../helpers/column_names";
import {clearMathCache} from "./cache";
import {updateSheetNames} from "./updater";


export function init(view, core, helpers){
	view.attachEvent("onBeforeSheetShow", function(){
		delete view._mathSheetCache[getActive(view)];
	});
	view.attachEvent("onSheetRename", function(name, newName){
		view._sheets.forEach((sheet)=>{
			let extra = {multiSheet:false};
			const edit = ["data", "editors", "conditions", "ranges"];
			edit.forEach((param)=>{
				if(sheet.content[param])
					sheet.content[param].forEach((value)=>{
						switch (param) {
							case "data":
								if(value[2][0] === "=")
									value[2] = updateSheetNames(value[2], name, newName, extra);
								break;
							case "editors": {
								const options = value[2].options;
								if(options){
									if(typeof options == "string")
										value[2].options = updateSheetNames(options, name, newName, extra);
									else
										for (let i = 0; i < options.length; i++){
											const option = typeof options[i] == "string" ? options[i] : options[i].value;
											options[i] = updateSheetNames(option, name, newName, extra);
										}
								}
								break;
							}
							case "conditions":
								if(value[3][0] === "=")
									value[3] = updateSheetNames(value[3], name, newName, extra);
								break;
							case "ranges":
								value[1] = updateSheetNames(value[1], name, newName, extra);
								break;
						}
					});
			});
			if(extra.multiSheet === true){
				delete view._mathSheetCache[sheet.name];
				getAccessor(view, sheet.name);
				clearMathCache();
			}
		});
		delete view._mathSheetCache[name];
	});
	view.attachEvent("onSheetRemove", function(name){
		delete view._mathSheetCache[name];
	});

	view._mathSheetCore = core;
	view._mathSheetHelpers = helpers;
}

export function getAccessor(view, name){
	const cache = view._mathSheetCache;

	if (!cache[name]){
		const helpers = (!name || name == getActive(view)) ? activeAccessor(view) : passiveAccessor(view, name);

		//invalid sheet name
		if(!helpers)
			return;

		const temp = cache[name] = SheetAccessor(helpers);

		temp.init();
	}
	return cache[name];
}

function SheetAccessor(helpers){
	return {
		init:function(){
			helpers.init();
		},
		getRangeCode(name){
			return helpers.getRangeCode(name);
		},
		getItem:function(r){
			return helpers.getRow(r);
		},
		getValue(r,c){
			var val = helpers.getCell(r,c);
			var test = val*1;
			if (!isNaN(test) && val !== "") return test;
			return val?val:"";
		},
		getRangeValue(name){
			var code = this.getRangeCode(name) || name;
			if (code.indexOf(":") == -1) return [];
			var pos = range(code);
			return this.getRange.apply(this, pos);
		},
		getRange(r1,c1,r2,c2){
			const set = helpers.getRange(r1,c1,r2,c2);
			for (let i = set.length-1; i>=0; i--){
				let val = set[i];
				let test = val !== "" ? val * 1 : "";
				set[i] = isNaN(test) ? (val?val:"") : test;
			}
			return set;
		},
		getName: function(){
			return helpers.getName();
		},
		getRangeCols:function(){
			return getRangeCols.apply(helpers, arguments);
		},
		getRangeRows:function(){
			return getRangeRows.apply(helpers, arguments);
		}
	};
}

function activeAccessor(view){
	return {
		init:function(){

		},
		getRangeCode(name){
			return view.ranges.getCode(name);
		},
		getRow:function(r){
			return view.getRow(r);
		},
		getCell:function(r,c){
			return view.getRow(r)[c];
		},
		getName: function(){
			return "";
		},
		getRange:function(r1,c1,r2,c2){
			var set = [];
			for (var i = r1; i <= r2; i++){
				var item = view.getRow(i);
				for (var j = c1; j <= c2; j++)
					set.push(item[j]);
			}
			return set;
		}
	};
}

function passiveAccessor(view, name){
	const sheet = getSheet(view, name);
	if(!sheet)
		return;

	const page = sheet.content;
	const table = [];
	const formulas = [];

	const ranges = [];

	return {
		init:function(){
			if (page.ranges){
				for (var i = 0; i < page.ranges.length; i++) {
					var line = page.ranges[i];
					ranges[line[0]] = line[1];
				}
			}

			if (page.data){
				for (let i = 0; i < page.data.length; i++) {
					//expand data structure
					let [row, column, value] = page.data[i];
					if (!table[row])
						table[row] = [];
					table[row][column] = value;

					if (value[0] === "="){
						//for math cells, store the code
						let math = view._mathSheetHelpers.parse(value, view._mathSheetCore, name);
						table[row][column] = math.handler;

						//store list of cross-sheet formulas
						for (var j=0; j<math.triggers.length; j++){
							if (math.triggers[j][5]){
								formulas.push(page.data[i]);
								break;
							}
						}
					}
				}
			}
		},
		getRangeCode(name){
			return ranges[name];
		},
		getRow(i){
			return table[i] || [];
		},
		getCell(i, j){
			var value = this.getRow(i)[j];
			if (typeof value === "function")
				return view._mathSheetHelpers.execute(value, view._mathSheetCore);

			return value;
		},
		getName:function(){
			return name;
		},
		getRange:function(r1,c1,r2,c2){
			var set = [];
			for (var i = r1; i <= r2; i++)
				for (var j = c1; j <= c2; j++)
					set.push(this.getCell(i,j));
			return set;
		}
	};
}

function getRangeCols(r1,c1,r2,c2,lastIndex){
	lastIndex = lastIndex || 0;
	const data = [];
	const rangeToData = {};
	for (let i = r1, rind = 0; i <= r2; i++, rind++){
		data[rind] = {};
		const item = this.getRow(i);
		for (let j = c1, cind = lastIndex; j <= c2; j++, cind++){
			if(i == r2){
				const sheetName = this.getName();
				rangeToData[`${sheetName ? sheetName+"!" : ""}${encode[j]}${r1}:${encode[j]}${r2}`] = `data${cind}`;
				lastIndex++;
			}
			data[rind][`data${cind}`] = item[j];
		}
	}
	return [data, rangeToData, lastIndex];
}

function getRangeRows(r1,c1,r2,c2,lastIndex){
	lastIndex = lastIndex || 0;
	const data = [];
	const rangeToData = {};
	for (let i = c1, cind = 0; i <= c2; i++, cind++){
		data[cind] = {};
		for (let j = r1, rind = lastIndex; j <= r2; j++, rind++){
			const item = this.getRow(j);
			if(i == c2){
				const sheetName = this.getName();
				rangeToData[`${sheetName ? sheetName+"!" : ""}${encode[c1]}${j}:${encode[c2]}${j}`] = `data${rind}`;
				lastIndex++;
			}
			data[cind][`data${rind}`] = item[i];
		}
	}
	return [data, rangeToData, lastIndex];
}