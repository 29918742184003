import {getType} from "./types";

export function init(view){
	//parsing initial data
	view.attachEvent("onDataParse", (data) => _parse(view, data));
	view.attachEvent("onDataSerialize", (data, config) => _serialize(view, data, config));

	// undo
	view.attachEvent("onUndo", (type, row, column, value) => {
		if(type == "value")
			_undoValue(view, row, column, value);
	});
}

function _serialize(view, obj, config){
	var math = !config || config.math!==false;
	var data = [];
	var grid = view.$$("cells");
	var state = grid.getState();
	var columns = state.ids.concat(state.hidden);

	// remove "rowId" column
	columns.splice(columns.indexOf("rowId"), 1);

	grid.eachRow(function(row){
		const item = this.getItem(row);
		for (var i=0; i<columns.length; i++){
			const key = columns[i];
			let value = item[key];
			const css = item.$cellCss ? (item.$cellCss[key] || "") : "";

			//serialize instead of value if defined
			if (math && item["$"+key])
				value = item["$"+key];

			//put not empty values in serialization
			const hasValue = value || value === 0;
			if (hasValue || css) {
				const type = getType(view, row, key);
				data.push([row*1, key*1, (hasValue ? value : ""), css, type]);
			}
		}
	}, true);
	obj.table = {
		frozenColumns : (view._frozenColumns || 0),
		frozenRows : (view._frozenRows || 0)
	};

	obj.data = data;
}

function _parse(view, obj){
	var grid = view.$$("cells");

	var tconfig = obj.table || { frozenColumns : 0, frozenRows : 0 };
	view._frozenColumns = view._frozenRows = 0;
	if (tconfig){
		if (!webix.isUndefined(tconfig.frozenColumns) && (tconfig.frozenColumns+1) != grid.config.leftSplit)
			view.freezeColumns(tconfig.frozenColumns);
		if (!webix.isUndefined(tconfig.frozenRows) && tconfig.frozenRows != grid.config.topSplit)
			view.freezeRows(tconfig.frozenRows);
	}

	if(obj.sizes)
		grid.define("fixedRowHeight", false);

	for (var i = 0; i < obj.data.length; i++){
		var [ row, column, value, , type ] = obj.data[i];

		if(typeof value == "string")
			value = value.replace(/^=link\(/i, "=HYPERLINK("); // LINK is deprecated

		var item = grid.getItem(row);
		item[column] = value;

		//cell value can be updated via types module
		view.ignoreUndo(()=>{
			view.callEvent("onCellChange", [row, column, value, type]);
		});
	}
}

function _undoValue(view, row, column, value){
	view.setCellValue(row, column, value);
}