export function getRow(id){
	return this._table.getItem(id);
}

export function setCellEditor(row, column, editor) {
	const line = this._table._ssEditors[row] = this._table._ssEditors[row] || {};
	const value = line[column] || this.getRow(row)[column];
	line[column] = editor;

	if (editor && editor.editor){
		if (this.getCellFilter(row, column))
			this.setCellFilter(row, column, null);
		this._table.addCellCss(row,column, "ss_editor");
	} else {
		delete this._table._ssEditors[row][column];
		this._table.removeCellCss(row,column, "ss_editor");
	}
	this.callEvent("onAction", ["dropdown", { row, column, value, newValue:editor }]);
}

export function getCellEditor(row, column){
	let line = this._table._ssEditors[row] || {};
	return line[column] || null;
}