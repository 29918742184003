import {range, toRange, changeRange} from "../helpers/column_names";
import {getAccessor} from "../math/sheet";
import {ignoreUndo} from "./undo";

export function init(view){
	const grid = view._table.$view.querySelector(".webix_ss_center");

	view.views = {
		register:function(type, render, track){
			if(!this._types[type])
				this._types[type] = { render:render, track:track };
		},
		move: function(id, x, y){
			const win = webix.$$(id);
			const old = webix.copy(this._pull[id]);
			this._pull[id].x = _correctPos(view, win, x, "x");
			this._pull[id].y = _correctPos(view, win, y, "y");
			view.callEvent("onAction", ["move-view", { row:id, newValue:webix.copy(this._pull[id]), value:old} ]);
			_showOne(view, win, this._pull[id]);
		},
		add: function(x, y, type, data, config, id){
			config = config || {};
			if(type == "chart"){
				config = webix.extend(config, {
					type: "line",
					dataSeries:"columns"
				});
			}

			const height =  config.height || 300;
			const width = config.width || 500;

			const win = webix.ui({ 
				id:id || "$ssheet-ui"+webix.uid(), // fixed id is for undo only
				view: "ssheet-ui",
				container:grid, master:view._table.config.id,
				width:width, height:height
			});

			win.attachEvent("onViewMoveEnd", (x, y) => {
				const state = view._table.getScrollState();
				this.move(id, x, y+state.y);
			});
			win.attachEvent("onViewResize", () => {
				const conf =  this._pull[id];
				const old = webix.copy(conf);

				conf.config.height = win.$height;
				conf.config.width = win.$width;
				view.callEvent("onAction", ["resize-view", { row:id, newValue:webix.copy(conf), value:old } ]);
			});
			win.attachEvent("onViewEdit", () => {
				const config = this._pull[id];
				const command = this._commands[config.type] || config.type;
				view.callEvent("onCommand", [{ id:command, viewid:id, config }]);
			});
			win.attachEvent("onViewRemove", () => this.remove(id));

			id = win.config.id;

			x = _correctPos(view, win, x, "x");
			y = _correctPos(view, win, y, "y");

			this._pull[id] = { x, y, type, data, config };
			this.update(id, data, config, true);
			_showOne(view, win, this._pull[id]);

			return id; 
		},
		remove: function(id){
			const old = this._pull[id];
			webix.$$(id).close();
			delete this._pull[id];

			view.callEvent("onAction", ["add-view", { row:id, newValue:null, value:old } ]);
		},
		get:function(id){
			return webix.$$(id).getBody();
		},
		update:function(id, data, config, inner){
			const old = inner ? null : webix.copy(this._pull[id]);
			const conf = this._pull[id];
			const actions = this._types[conf.type];

			data = data || config.data || "";
			config = config || {};

			delete config.data;

			if(conf.type == "chart"){
				const type = config.type;
				if(type && !_isPie(type) && !config.series){
					const xAxis = config.xAxis && config.xAxis.range;
					const legend = config.legend && config.legend.range;
					config.series = _getSeries(view, data, config.dataSeries, {xAxis, legend});
				}
			}

			const win = webix.$$(id);

			const height = config.height || 300;
			const width = config.width || 500;

			if(win.$width != width && win.$height != height){
				win.define({width, height});
				win.resize();
			}

			conf.data = data;
			conf.config = config;

			let node = this.get(id);

			data = _fromRange(view, conf);

			if(actions.render){
				const renderConfig = webix.copy(conf);

				//clear width and height for inner view resizing
				renderConfig.config.width = renderConfig.config.height = 0;

				node = actions.render(node, renderConfig, data);
				_processTables(node);
			}

			if(actions.track)
				_trackOne(view, node, actions.track, conf);

			view.callEvent("onAction", ["add-view", { row:id, newValue:webix.copy(this._pull[id]), value:old} ]);
		},
		_commands:{
			"image":"add-image-top",
			"chart":"add-chart"
		},
		_types:{},
		_pull:{}
	};

	// handle events
	view._table.attachEvent("onResize", () => _showAll(view) );
	view._table.attachEvent("onScrollY", () => _showAll(view) );
	view._table.attachEvent("onScrollX", () => _showAll(view) );

	view.attachEvent("onReset", () => _reset(view));
	view.attachEvent("onUndo", (type, row, column, value) => {
		const move = type === "move-view";
		if (move || type == "resize-view" || type === "add-view")
			_undoUI(view, move, row, value);
	});
	view.attachEvent("onAction", (action, p) => {
		if(action == "header-hide")
			_showAll(view);
		else if(action == "before-grid-change")
			_updateData(view, p.name, p.inc, p.data, p.start);
	});
	view.attachEvent("onColumnOperation", () =>  _showAll(view, true));
	view.attachEvent("onRowOperation", () => _showAll(view, true));
	view.attachEvent("onDataParse", (data) => _parse(view, data));
	view.attachEvent("onDataSerialize", (data, config) => _serialize(view, data, config));
	view.attachEvent("onCellChange", () => _trackAll(view));
	view.attachEvent("onMathRefresh", () => _trackAll(view));

	view.attachEvent("onDestruct", () => {
		for(let i in view.views._pull) webix.$$(i).destructor();
	});

	// set default views
	view.views.register("chart", (node, conf, data) => {
		const dataRange = _getNames(view, conf);
		const config = _genChartConfig(conf, data, dataRange);
		return webix.ui(config, node);
	}, (view, data) => {
		view.clearAll();
		view.parse(data);
	});

	view.views.register("image", (node, conf, data) => {
		const config = webix.extend({ css:"webix_ssheet_bgimage", template:`<img src="${data}"/>` }, conf.config||{}, true );
		return webix.ui(config, node);
	});
}

function _parse(view, data){
	webix.delay(function(){ //[fixme] wait for dom, otherwise legend is misplaced https://tracker.webix.io/issue/WS-1318
		const views = data.views;
		if(views){
			ignoreUndo(function(){
				views.forEach(obj => view.views.add.apply(view.views, obj));
			}, view);
		}
	});
}

function _serialize(view, data, config){
	data.views = [];
	for(let i in view.views._pull){
		const conf = view.views._pull[i];
		const one = [conf.x, conf.y, conf.type, conf.data, conf.config];
		if(config && config.viewIds)
			one.push(i);
		data.views.push(one);
	}
}

let thread;
function _trackAll(view){
	clearTimeout(thread);
	thread = webix.delay(() => {
		const ui = view.views;
		for(let id in ui._pull){
			const track = ui._types[ui._pull[id].type].track;
			if(track){
				_trackOne(view, ui.get(id), track, ui._pull[id]);
			}
		}
	});
}

function _trackOne(view, node, track, conf){
	const data = _fromRange(view, conf, true);
	track(node, data);
}

function _updateData(view, name, inc, data, start){
	const views = data.views;
	for(let i = 0; i < views.length; i++){
		if(_isRange(views[i][3]))
			views[i][3] = changeRange(views[i][3], name, inc, start);
	}
} 

function _showAll(view, pos){
	//close possibly open menus of embedded ui
	webix.callEvent("onClick", []);

	for(let id in view.views._pull){
		const win = webix.$$(id);
		const conf = view.views._pull[id];
		if(pos){
			conf.x = _correctPos(view, win, conf.x, "x");
			conf.y = _correctPos(view, win, conf.y, "y");
		}
		_showOne(view, win, conf);
	}
}

function _showOne(view, win, conf){
	const state = view._table.getScrollState();

	win.show({
		x:conf.x,
		y:conf.y-state.y
	});
}

function _correctPos(view, win, v, type){
	if(!v && v !==0 )
		v = view._table.getScrollState()[type]+50;
	else{
		const size = type == "x" ? _getWidth(view)-win.$width : _getHeight(view)-win.$height;
		v = Math.min(Math.max(v, 0), size);
	}	
	return v;
}

function _reset(view){
	ignoreUndo(function(){
		for(let id in view.views._pull)
			view.views.remove(id);
	}, view);
}

function _undoUI(view, move, id, value){
	if(value){
		const {x, y, type, data, config} = value;
		if(view.views._pull[id]){
			if(move)
				view.views.move(id, x, y);
			else
				view.views.update(id, data, config);
		}
		else
			view.views.add(x, y, type, data, config, id);

		const editor = view.$$("chartEditor");
		if(editor && editor.viewId == id)
			editor.queryView("form").setValues(config);
	}
	else
		view.views.remove(id);
}

function _getWidth(view){
	let width = 0;
	view._table.eachColumn(function(id){
		if(id !== "rowId")
			width += this.getColumnConfig(id).width;
	});
	return width;
}

function _getHeight(view){
	let height = 0;
	view._table.data.each(function(obj){
		height += obj.$height || webix.skin.$active.rowHeight;
	});
	return height;
}

//disable datatables not to interfere with ss table events
function _processTables(node){
	const query = function(view){
		return view.name == "datatable" || view.name == "treetable";
	};
	let tables = node.queryView(query, "self");
	if(tables) tables = [tables];
	else tables = node.queryView(query, "all");
	tables.forEach((dt) => dt.disable());
}

/* chart-specific helpers */

function _genChartConfig(conf, data, dataRange){
	const htmlFilter = /<[^>]*>/gi;
	let config = webix.copy(conf.config);

	config = _normalizeConfig(config);

	const scale = config.scale.lines;
	const scaleColor = config.scale.color || undefined;

	const type = config.type;

	if(_isPie(type))
		delete config.series;

	const legendConfig = config.legend;
	let legendRange = !legendConfig.fromData && legendConfig.range;
	if(legendRange){
		const switchDirection =  config.dataSeries == "rows";
		legendRange = _getParts(legendConfig.range, switchDirection, dataRange);
	}

	const legend = {
		align: legendConfig.align || "center",
		valign: legendConfig.valign || "bottom"
	};

	legend.layout = legend.valign == "middle" ? "y" : "x";

	if(_isPie(type))
		_pieConfig(data, config, legend, legendRange);
	else if(config.series){
		legend.values = [];

		const lines = config.series;
		config.series = [];

		const xAxis = config.xAxis;
		if(xAxis.fromData)
			xAxis.range = legendRange ? Object.keys(data[0]).filter(line => legendRange.indexOf(line) == -1)[0] : "data0";
		else if(xAxis.range)
			xAxis.range = dataRange[xAxis.range];

		for(let i = 0; i < lines.length; i++){
			const color = lines[i].color;
			const item = dataRange[lines[i].range];

			if(item != xAxis.range){
				const s = {
					value:`#${item}#`,
					tooltip:{
						template: lines[i].tooltip ? `#${item}#` : ""
					}
				};

				if(type == "radar" || type == "line" || type == "spline"){
					s.line = { color:color };
					s.item = { borderColor:color, type:lines[i].marker };
				}
				else
					s.color = color;
				if(type == "area" || type == "splineArea")
					s.alpha = 0.7;

				if(legendRange || legendConfig.fromData){
					const val = legendRange ? data[0][legendRange[i]] : data[0][item];
					const text = (val || val === 0) ? (val+"").replace(htmlFilter, "") : "";
					legend.values.push({text, color});
				}

				config.series.push(s);
			}
		}

		if(xAxis.range){
			config.xAxis = {
				template: obj => {
					const val = obj[xAxis.range];
					return (val || val === 0) ? (val+"").replace(htmlFilter, "") : "";
				},
				title: config.xAxis.title,
				lineColor: scaleColor,
				color: scaleColor,
				lines: scale
			};
		}
		else
			delete config.xAxis;

		if(legend.values.length)
			config.legend = legend;
		else
			delete config.legend;
	}

	config.yAxis = webix.extend(config.yAxis||{}, {
		lineColor: scaleColor,
		color: scaleColor,
		lines: scale,
		lineShape: config.scale.circle ? "arc" : "default"
	});

	return webix.extend({
		view:"chart"
	}, config, true);
}

function _pieConfig(data, config, legend, legendRange){
	const htmlFilter = /<[^>]*>/gi;
	for(var i in data[0]){
		if(config.legend.fromData){
			legend.template = obj => {
				const val = obj[i];
				return (val || val === 0) ? (val+"").replace(htmlFilter, "") : "";
			}; //data0
			config.value = `#${i.replace("0", "1")}#`; //data1
		}
		else{
			if(config.legend.range)
				legend.template = obj => {
					const val = obj[legendRange[0]];
					return (val || val === 0) ? (val+"").replace(htmlFilter, "") : "";
				};
			config.value = `#${i}#`;
		}
		break; 
	}
	if(legend.template)
		config.legend = legend;
	else
		delete config.legend;

	if(config.pieInnerText)
		config.pieInnerText = config.value;
}

function _isRange(data){
	return typeof data == "string" && data.indexOf("data:image") === -1;
}

function _fromRange(view, conf, parse){
	let data = conf.data;

	if(/^(http|data:image|\/)/i.test(data))
		return data;

	const arr = [];
	if(data){
		const config = conf.config;
		const switchDirection = config.dataSeries == "rows";
		let lastIndex = 0;

		data = data.split(",");
		for(let i = 0; i < data.length; i++){
			const cells = _isRange(data[i]) ? range(data[i], view) : null;
			if(cells){
				const page = getAccessor(view, cells[4]);
				cells[4] = lastIndex;

				const subdata = page ? page[switchDirection ? "getRangeRows" : "getRangeCols"].apply(page, cells) : null;
				if(subdata){
					lastIndex += subdata[2];
					for(let i = 0; i < subdata[0].length; i++){
						if(!arr[i])
							arr[i] = {};
						webix.extend(arr[i], subdata[0][i]);
					}
				}
			}
		}

		if(parse && config && !_isPie(config.type)){
			if(typeof(config.legend) != "object")
				config.legend = {fromData: !!config.legend};

			const legend = config.legend;

			if(legend.fromData)
				arr.shift();
			else if(legend.range){
				const names = _getNames(view, conf);
				const legendRange = _getParts(legend.range, switchDirection, names);
				for (let i = 0; i < legendRange.length; i++){
					const item = legendRange[i];
					delete arr[0][item];
				}
			}
		}
	}
	return arr;
}

function _getNames(view, conf){
	let data = conf.data;

	if(/^(http|data:image|\/)/i.test(data))
		return;

	let names = {};
	if(data){
		const config = conf.config;
		let lastIndex = 0;
		data = data.split(",");
		for(let i = 0; i < data.length; i++){
			const cells = _isRange(data[i]) ? range(data[i], view) : null;
			if(cells){
				const page = getAccessor(view, cells[4]);
				cells[4] = lastIndex;

				const subdata = page ? page[config.dataSeries == "rows" ? "getRangeRows" : "getRangeCols"].apply(page, cells) : null;
				if(subdata){
					lastIndex += subdata[2];
					webix.extend(names, subdata[1]);
				}
			}
		}
	}
	return names;
}

export function _isPie(type){
	return /pie|donut/.test(type);
}

export function _getSeries(view, data, dataSeries, active){
	const dir = dataSeries == "rows";
	let vals = [];

	if(data){
		let xAxis = [];
		let legend = [];
		let activeVals = [];

		if(active){
			if(active.legend)
				legend = _getParts(active.legend, dir);
			if(active.xAxis)
				xAxis = _getParts(active.xAxis, dir);
			if(active.series)
				activeVals = active.series.filter(val => legend.indexOf(val.range) == -1 && xAxis.indexOf(val.range) == -1);
		}

		data = data.split(",");
		let colorIndex = 0;
		data.forEach(subdata => {
			const series = range(subdata, view);
			if(series){
				const start = dir ? series[0] : series[1];
				const end = dir ? series[2] : series[3];

				for(let i = start; i <= end; i++){
					const range = dir ? toRange(i, series[1], i, series[3], series[4]) : toRange(series[0], i, series[2], i, series[4]);
					if(legend.indexOf(range) != -1 || xAxis.indexOf(range) != -1)
						continue;

					const activeVal = activeVals.filter(val => val.range == range);

					vals.push(activeVal[0] || {
						range,
						marker: "square",
						tooltip: 1,
						color: _getColor(colorIndex)
					});

					colorIndex++;
				}
			}
		});
	}
	return vals;
}

export function _getParts(ranges, dir, dataRange){
	const parts = [];

	ranges = ranges.split(",");
	ranges.forEach(subRange => {
		subRange = range(subRange);
		const start = dir ? subRange[0] : subRange[1];
		const end = dir ? subRange[2] : subRange[3];

		for(let i = start; i <= end; i++){
			const range = dir ?
				toRange(i, subRange[1], i, subRange[3], subRange[4]):
				toRange(subRange[0], i, subRange[2], i, subRange[4]);

			parts.push(dataRange ? dataRange[range] : range);
		}
	});

	return parts;
}

export function _normalizeConfig(config){
	if(typeof(config.xAxis) != "object")
		config.xAxis = {fromData: !!config.xAxis};

	if(typeof(config.legend) != "object")
		config.legend = {fromData: !!config.legend};

	config.legend = webix.extend(config.legend, {
		layout:"x",
		align:"center",
		valign:"bottom"
	});

	if(!config.scale)
		config.scale = {
			lines:1
		};

	return config;
}

function _getColor(index){
	const colors = [
		"#e06666", "#6aa84f", "#3c78d8", "#e69138", "#783f04", "#134f5c", "#674ea7", "#c27ba0"
	];
	return colors[index%colors.length];
}