import {updateMath}  from "../math/updater";
import {clearMathCache} from "../math/cache";
import {ignoreReset, ignoreUndo}  from "../operations/undo";
import group  from "../operations/undo_group";
import {adjustSpan}  from "../operations/spans";

export function init(view){
	view.attachEvent("onCommand", (action, start, end) => {
		if (action.id == "add" || action.id == "del"){
			var area = view._table.getSelectArea();
			if(area && !(start && end)){
				start = area.start;
				end = area.end;
			}

			if (start && end){
				group.set(function(){
					process(action, start, end, view);
				});
			}
		}
	});

	view.attachEvent("onUndo", (action, row, column, value) => {
		if(action == "grid-change"){
			clearMathCache(true);
			loadChangedData(view,value);
		}
	});
}

export function process(action, start, end, view, e, r) {
	var data, i, newData, oldData, values,
		count=0, origin=0;

	var select = view._table.getSelectArea();
	var state = view._table.getScrollState();

	newData = view.serialize({
		math: true, viewIds:true
	});

	oldData = webix.copy(newData);

	var spans = newData.spans;
	for (var s in spans){
		var span = spans[s];
		span = adjustSpan(span, action, start, end, newData.data);
		if(span[2] <= 0 || span[3] <= 0){
			spans.splice(s, 1);
		}
	}

	values = getUpdateValues(action, start, end);

	view.$handleSelection = null;	

	if(!e)
		ignoreUndo(function(){
			view.callEvent("onAction", ["before-grid-change", {
				name: values.name,
				inc: values.inc,
				data:newData,
				start: start
			}]);
		},view);

	data = newData.data;

	if (action.group == "column") {
		count = end.column - start.column + 1;
		origin = start.column;

		if (action.id == "add")
			_addColumn(action, start, end, view, e, r, data);
		else if (action.id == "del"){
			_delColumn(action, start, end, view, e, r, data);
			count = -count;
		}
	} else if (action.group == "row") {
		count = end.row - start.row + 1;
		origin = start.row;

		if (action.id == "add")
			_addRow(action, start, end, view, e, r, data);
		else if (action.id == "del"){
			_delRow(action, start, end, view, e, r, data);
			count = -count;
		}
	}

	//update formulas
	var update = { id:action.group, start:origin, count:count };
	i = data.length;
	while (i--) {
		if (data[i][2] && typeof data[i][2] === "string" && data[i][2].substr(0, 1) == "=")
			data[i][2] = updateMath(data[i][2], update);
	}

	view.callEvent("onAction", ["grid-change",{value: oldData, newValue: newData} ]);

	ignoreUndo(function() {
		loadChangedData(view, newData);
		view._table.scrollTo(state.x, state.y);
	},view);

	if (select){
		var grid = view.$$("cells");
		select = fixSelectArea(select, grid);
		if (select)
			grid.addSelectArea(select.start, select.end);
	}
}

function fixSelectArea(select, grid){
	var rows = grid.count();
	var cols = grid.config.columns.length;
	if (select.start.row*1 > rows) return null;
	if (select.end.row*1 > rows) return null;
	if (select.start.column*1 > cols) return null;
	if (select.end.column*1 > cols) return null;
	
	return select;
}

function loadChangedData(view, data){
	// data loading resets undo history, and
	// we need to preserve it
	ignoreReset(function(){
		view.$handleSelection = null;
		view.parse(data);
	});
}

function getUpdateValues( action, start, end){
	var name = action.group,
		inc = action.id=="add"?1:(action.id=="del"?-1:0);

	if(inc) {
		// span support
		if (name == "row")
			inc += inc * (end.row - start.row);
		if (name == "column")
			inc += inc * (end.column - start.column);
	}
	return {name: name, inc: inc};
}

function _addColumn(action, start, end, view, silent, value, data) {
	let i = data.length;
	let add_column_count = end.column - start.column + 1;
	view.config.columnCount += add_column_count;
	view.reset();

	while (i--)
		if (data[i][1] >= start.column)
			data[i][1] += add_column_count;

	if (!silent)
		view.callEvent("onColumnOperation", [action, start, end, null]); //add item to history
	else
		data.push(...value); //recovering value which has been deleted
}

function _delColumn(action, start, end, view, silent, value, data) {
	let i = data.length;
	let del_column_count = end.column - start.column + 1;

	if (view.config.columnCount === del_column_count){
		if (start.column == end.column)
			return; //deleting last column, ignoring
		//prevent deleting all columns
		end.column--;
		del_column_count--;
	}

	view.config.columnCount -= del_column_count;


	view.reset();
	let deleted = []; // for history

	while (i--)
		if (data[i][1] >= start.column && data[i][1] <= end.column)
			deleted.push(data.splice(i, 1)[0]);
		else if (data[i][1] > end.column)
			data[i][1] -= del_column_count;

	if (!silent)
		view.callEvent("onColumnOperation", [action, start, end, deleted]);
}

function _addRow(action, start, end, view, silent, value, data) {
	let i = data.length;
	let add_row_count = end.row - start.row + 1;
	view.config.rowCount += add_row_count;

	view.reset();

	while (i--)
		if (data[i][0] >= start.row)
			data[i][0] += add_row_count;

	if (!silent) {
		view.callEvent("onRowOperation", [action, start, end, null]);
	} else {
		data.push(...value);
	}
}

function _delRow(action, start, end, view, silent, value, data) {
	let i = data.length;
	let del_row_count = end.row - start.row + 1;

	if (view.config.rowCount === del_row_count){
		if (start.row == end.row)
			return; //deleting last column, ignoring
		//prevent deleting all columns
		end.row--;
		del_row_count--;
	}

	view.config.rowCount -= del_row_count;

	view.reset();

	let deleted = [];
	while (i--)
		if (data[i][0] >= start.row && data[i][0] <= end.row)
			deleted.push(data.splice(i, 1)[0]);
		else if (data[i][0] > end.row)
			data[i][0] -= del_row_count;

	if (!silent)
		view.callEvent("onRowOperation", [action, start, end, deleted]);
}