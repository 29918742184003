import {Dialog} from "./common";

export const action = "print";

export class DialogBox extends Dialog{
	$show(box, form){
		form.setValues({
			data:"current",
			paper:"A4",
			fit:"page",
			mode:"landscape",
			sheetnames:1,
			margin:0
		});
	}
	$init(){
		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["print-title"],
			move: true,
			modal:true,
			width:530, height:520,
			position: "center",
			buttons:false,
			body:{
				view:"form",
				elements:[
					{ type:"section", template: webix.i18n.spreadsheet.labels["print-settings"]},
					{ cols:[
						{ view:"radio", name:"data",vertical:true, options:[
							{ id:"current", value:webix.i18n.spreadsheet.labels["current-sheet"]},
							{ id:"all", value:webix.i18n.spreadsheet.labels["all-sheets"]},
							{ id:"selection", value:webix.i18n.spreadsheet.labels["selection"]}
						], on:{
							onChange:function(newv){
								if(newv == "all"){
									var form = this.getFormView();
									form.elements.sheetnames.setValue(1);
								}
							}
						}},
						{ rows:[
							{view:"checkbox", name:"sheetnames", labelRight:webix.i18n.spreadsheet.labels["sheet-names"]},
							{view:"checkbox", name:"borderless", labelRight:webix.i18n.spreadsheet.labels["borderless"]},
							{view:"checkbox", name:"skiprows", labelRight:webix.i18n.spreadsheet.labels["skip-rows"]},
							{view:"checkbox", name:"margin", labelRight:webix.i18n.spreadsheet.labels["margin"]}
						]}
					]},
					{ type:"section", template: webix.i18n.spreadsheet.labels["print-paper"]},
					{ view:"radio", name:"paper", options:[
						{ id:"letter", value:webix.i18n.spreadsheet.labels["page-letter"]},
						{ id:"A4", value:webix.i18n.spreadsheet.labels["page-a4"]},
						{ id:"A3", value:webix.i18n.spreadsheet.labels["page-a3"]}
					]},
					{ type:"section", template: webix.i18n.spreadsheet.labels["print-layout"]},
					{ cols:[
						{view:"radio", name:"fit", options:[
							{ id:"page", value:webix.i18n.spreadsheet.labels["page-width"]},
							{ id:"data", value:webix.i18n.spreadsheet.labels["page-actual"]}
						]},
						{ width:25},
						{ view:"radio", width:220, name:"mode", options:[
							{ id:"portrait", value:webix.i18n.spreadsheet.labels["page-portrait"]},
							{ id:"landscape", value:webix.i18n.spreadsheet.labels["page-landscape"]}
						]}
					]},
					{
						cols:[
							{ },
							{ view: "button", css:"ssheet_cancel_button", value: webix.i18n.spreadsheet.labels.cancel, autowidth:true,
								click: () => this.close()
							},
							{ view: "button", value: webix.i18n.spreadsheet.labels.print, autowidth:true,
								click: () => this.apply(this)
							}
						]
					}
				]
			},
			on:{
				onHideClick: () => this.close()
			}
		};
	}
	apply(dialog){
		var data = dialog.$dialog.getBody().getValues();
		data.margin = data.margin?0:{};
		this.close();
		webix.print(dialog.view, data);
		
	}
}
