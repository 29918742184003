import group from "./undo_group";
import {setSpanCss} from "./spans";
import {rangeOptions} from "../math/ranges";
import * as fmt from "../operations/formats";
import {changeRange, eachRange} from "../helpers/column_names";

export function init(view){
	const table = view._table;

	view.attachEvent("onReset", () => reset(view));
	reset(view);

	table.attachEvent("onBeforeEditStart", function(id){
		let col = this.getColumnConfig(id.column);
		if (this._ssEditors[id.row]){
			let ed = this._ssEditors[id.row][id.column];
			if (ed){
				webix.extend(col, ed, true);
				webix.extend(col, {row: view.getRow(id.row)}, true);
				if (ed.options){
					if (typeof ed.options == "string")
						col.options = rangeOptions(view, ed.options, { unique:true, order:true, empty:true });
					else if (webix.isArray(ed.options))
						col.options = rangeOptions(view, ed.options, { empty:true });
				}
			}
		}
	});

	// update format if editor supports this feature
	table.attachEvent("onBeforeEditStop", function(state, editor, ignore){
		if (!ignore && editor.getFormat){
			const value = editor.getValue();
			let format = editor.getFormat(value);

			group.set(function(){
				view.setCellValue(editor.row, editor.column, value);
				if (format){
					fmt.addFormat(view, editor.row, editor.column, fmt.getFormatSource(format));
				} else
					fmt.removeFormat(view, editor.row, editor.column);
			});

			const cell = view.getSelectedId();
			if (cell && cell.row == editor.row && cell.column == editor.column){
				format = format || "common";
				format = fmt.isCustom(format) ? "custom" : format;
				view.callEvent("onCommand", [{id:"toolbar-update", name:"format", value:format}]);
			}
		}
	});

	table.attachEvent("onAfterEditStop", function(state, editor){
		const col = this.getColumnConfig(editor.column);

		col.editor = "text";
		delete col.popup; delete col.$popup; delete col.row;
	});

	view.attachEvent("onUndo", (type, row, column, value) => {
		if (type == "dropdown")
			view.setCellEditor(row, column, value);
	});

	view.attachEvent("onAction", (action, p) => {
		if (action == "dropdown"){
			setSpanCss(view, p.row, p.column, "ss_editor", p.newValue);
		}
		else if (action == "before-grid-change")
			updatePosition(p.name,p.inc,p.data, p.start);
	});
}

function reset(view){
	view._table._ssEditors = {};
}

export function serialize(view,data){
	var row, column,
		tblEditors = view._table._ssEditors,
		editors = [];

	for (row in tblEditors){
		for (column in tblEditors[row])
			editors.push([row, column, tblEditors[row][column]]);
	}
	data.editors = editors;
}

export function load(view,data){
	const editors = data.editors;

	if (editors)
		for (let i=0; i<editors.length; i++)
			view.setCellEditor.apply(view, editors[i]);
}

export function clearEditors(view, range){
	view._table.editStop();
	eachRange(range, view, function(view, cell){
		let editor = view._table._ssEditors[cell.row];
		if (editor){
			if (editor[cell.column]){
				delete editor[cell.column];
				view._table.removeCellCss(cell.row, cell.column, "ss_editor");
				setSpanCss(view, cell.row, cell.column, "ss_editor", false);
			}
		}
	});
}

export function isEditor(view, row, column){
	const editor = view._table._ssEditors;
	return editor[row] && !!editor[row][column];
}

// called on column/row add, delete, hide,show
function updatePosition(name, inc, data, start){
	const editors = data.editors;

	if (inc){
		// create new editors
		for(let i=editors.length-1; i>=0; i--){
			let [row,column,editor] = editors[i];
			if (name == "row" && row >= start.row || name == "column" && column >= start.column){

				if (name == "row"){
					if (row < start.row - inc){	//delete editor mark if row was deleted
						editors.splice(i,1);
						continue;
					} else						//update mark position if upper row was deleted
						editors[i][0] = row*1 + inc;
				}
				else if (name == "column"){
					if (column < start.column - inc){
						editors.splice(i, 1);
						continue;
					} else 
						editors[i][1] = column*1 + inc;
				}
				editor.options = changeRange(editor.options, name, inc, start);
			}
		}
	}
}

export function pasteDropdown(view, extra, row, col, cutted){
	const editor = extra.dropdown;

	view.setCellEditor(row, col, editor);
	if (cutted === 1)
		view.setCellEditor(extra.row, extra.col, {});
}