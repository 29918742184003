import {Dialog} from "./common";

export const action = "add-link";

export class DialogBox extends Dialog{
	$show(box, form){
		var cell = this.view.getSelectedId();
		if(!cell)
			return false;
		if (!this.restoreValue(form))
			form.clear();
		form.elements.name.focus();
	}
	restoreValue(form){
		var cell = this.view.getSelectedId();
		if (cell){
			var item = this.view.getRow(cell.row);
			var value = item["$"+cell.column] || item[cell.column];
			if (value && value.indexOf("=HYPERLINK") === 0){
				var parts = value.split("\"");
				form.setValues({
					name: (parts[3] || ""),
					url: (parts[1] || "")
				});
				return true;
			}
		}
		return false;
	}

	$init(){
		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["link-title"],
			move: true,
			position: "center",
			body:{
				view:"form",
				elements:[
					{ view:"text", name:"name",  placeholder:webix.i18n.spreadsheet.labels["link-name"]},
					{ view:"text", name:"url",  placeholder:webix.i18n.spreadsheet.labels["link-url"]}
				]
			},
			on:{
				onSaveClick: () => okClick(this),
				onHideClick: () => this.close(),
				onCancelClick: () => this.close()
			}
		};
	}
}

function okClick(dialog){
	var cell = dialog.view.getSelectedId();
	var data = dialog.$dialog.getBody().getValues();

	var text = "";
	if (data.url){
		data.name = data.name || data.url;
		if (!/^https?:\/\//i.test(data.url))
			data.url = "http://" + data.url;
		text = `=HYPERLINK("${data.url}","${data.name}")`;
	}

	dialog.view.setCellValue(cell.row, cell.column, text);
	dialog.view.refresh();
	dialog.close();
}
