import {_to_number, _combine} from "./helpers";

/*Empty cells, logical values like TRUE, or text are ignored.*/
export function SUM(){
	var sum = 0, set = _combine(arguments);
	for (var i = 0; i < set.length; i++){
		var v = _to_number(set[i]);
		if (v !== false) sum+=v;
	}
	return sum;
}

/*If a range or cell reference argument contains text, logical values, or empty cells, those values are ignored; 
however, cells with the value zero are included.*/
export function AVERAGE(){
	var sum = 0, count = 0, set = _combine(arguments);
	for (var i = 0; i < set.length; i++){
		var v = _to_number(set[i]);
		if (v !== false){
			sum+=v; count++;
		}
	}
	return sum/count;
}

/*Empty cells, logical values, text, or error values in the array or reference are not counted. */
export function COUNT(){
	var count = 0, set = _combine(arguments);
	for (var i = 0; i < set.length; i++){
		var v = _to_number(set[i]);
		if (v !== false)
			count++;
	}
	return count;
}

/*counts the number of cells that are not empty in a range, zero is excluded.*/
export function COUNTA(){
	var count = 0, set = _combine(arguments);
	for (var i = 0; i < set.length; i++)
		if(set[i] && set[i]*1 !==0 ) count++;
	return count;
}

/*Counts empty cells in a specified range of cells. Cells with zero values are not counted.*/
export function COUNTBLANK(){
	var count = 0, set = _combine(arguments);
	for (var i = 0; i < set.length; i++)
		if(!set[i]*1) count++;
	return count;
}

/*Empty cells, logical values, or text in the array or reference are ignored.
If the arguments contain no numbers, MAX returns 0 (zero).*/
export function MAX(){
	var max = "", set = _combine(arguments);
	for (var i = 0; i < set.length; i++){
		var v = _to_number(set[i]);
		if (v !== false && (v>max || max ===""))
			max = v;
	}
	return max || 0;
}

/*Empty cells, logical values, or text in the array or reference are ignored. 
If the arguments contain no numbers, MIN returns 0.*/
export function MIN(){
	var min = "", set = _combine(arguments);
	for (var i = 0; i < set.length; i++){
		var v = _to_number(set[i]);
		if (v !== false && (v<min || min ===""))
			min = v;
	}
	return min || 0;
}

/* Only numbers in the array or reference are multiplied. 
Empty cells, logical values, and text in the array or reference are ignored.*/
export function PRODUCT(){
	var product = "", set = _combine(arguments);
	for (var i = 0; i < set.length; i++){
		var v = _to_number(set[i]);
		if(v !== false)
			product = product===""?v:product*v;
	}
	return product;
}

/* For valid products only numbers are multiplied. Empty cells, logical values, and text are ignored.
Treats array entries that are not numeric as if they were zeros.*/
export function SUMPRODUCT(sets){
	var length = sets[0].length;
	for(let i in sets)
		if(sets[i].length !== length)
			return;

	var sp = 0;
	for (let i = 0; i < sets[0].length; i++){
		var product = "";
		for(var s in sets){
			var v = _to_number(sets[s][i]);
			if(v !== false) product = product==="" ? v: product*v;
			else{ product = 0; break; }
		}
		if(!webix.isUndefined(product)) sp+=product;
	}
	return sp;
}

/*Empty cells, logical values, text, or error values in the array or reference are ignored. */
export function SUMSQ(){
	var sq = 0, set = _combine(arguments);
	for (var i = 0; i < set.length; i++){
		var v = _to_number(set[i]);
		if(typeof v ==="number")
			sq += Math.pow(v, 2);
	}
	return sq;
}

/*Empty cells, logical values, text, or error values in the array or reference are ignored. */
export function VARP(){
	var set = _combine(arguments);
	var count = this.COUNT(set);
	var avg = this.AVERAGE(set);
	
	var sum = 0;
	for (var i = 0; i < set.length; i++){
		var v = _to_number(set[i]);
		if(v !== false)
			sum += Math.pow(v - avg, 2);
	}
	return sum/count;
}

/* Empty cells, logical values, text, or error values in the array or reference are ignored. */
export function STDEVP(){
	var set = _combine(arguments);
	return Math.sqrt(this.VARP(set));
}

/*real numbers*/
export function POWER(num, pow){
	var n = _to_number(num), p = _to_number(pow);
	if(typeof n == "number" && typeof p =="number")
		return Math.pow(n, p);
	
}

/*real numbers*/
export function QUOTIENT(num, div){
	var n = _to_number(num), d = _to_number(div);
	if(typeof n == "number" && typeof d =="number")
		return n/d;
}

/*Returns a positive square root.*/
export function SQRT(num){
	var v = _to_number(num);
	if(v !== false && v>=0)
		return Math.sqrt(v);
}

export function ABS(num){
	var v = _to_number(num);
	if(v !== false)
		return Math.abs(v);
}

export function RAND(){
	return Math.random();
}

export function PI(){
	return Math.PI;
}

/*Rounds a number down to the nearest integer*/
export function INT(num){
	var v = _to_number(num);
	if(v !== false)
		return Math.round(v);
}

/*rounds a number to a specified number of digits*/
export function ROUND(num, digits){
	var v = _to_number(num);
	var d = _to_number(digits) || 0;
	if(v !== false)
		return parseFloat(v.toFixed(d));
}

/*rounds a number down to a specified number of digits*/
export function ROUNDDOWN(num, digits){
	var v = _to_number(num);
	var d = _to_number(digits) || 0;
	if(v !== false)
		return  Math.floor(v*Math.pow(10, d))/Math.pow(10,d);
}

/*rounds a number up to a specified number of digits*/
export function ROUNDUP(num,digits){
	var v = _to_number(num);
	var d = _to_number(digits) || 0;
	if(v !== false)
		return Math.ceil(v*Math.pow(10, d))/Math.pow(10,d);
}

/*Truncates a number to an integer by removing the fractional part of the number.*/
export function TRUNC(num){
	var v = _to_number(num);
	if(v !== false)
		return parseInt(v);
}

/*Returns number rounded up to the nearest even integer*/
export function EVEN(num){
	var v = _to_number(num);
	if(v !== false){
		var r = Math.round(v);
		return r%2?r+1:r;
	}
}

/*Returns number rounded up to the nearest odd integer*/
export function ODD(num){
	var v = _to_number(num);
	if(v !== false){
		var r = Math.round(v);
		return r%2?r:r+1;
	}
}