export function init(view) {
	view.attachEvent("onUndo", (action, row, column, value) => {
		if (action == "header-hide"){
			hideHeaders(view, value);
		}
	});
}

export function hideHeaders(view, state = true){
	var grid = view.$$("cells");
	if (state === "toggle")
		state = grid.config.header;

	if(grid.config.header && state){
		grid.config.header = false;
		if (grid.isColumnVisible("rowId"))
			grid.hideColumn("rowId", {}, true, true);
		grid.refreshColumns();
		view.callEvent("onCommand", [{id:"toolbar-update", name:"hide-headers", value:true}]);
		view.callEvent("onAction", ["header-hide", {newValue:true, value:false}]);
	}
	else if(!grid.config.header && !state){
		grid.config.header = true;
		if (!grid.isColumnVisible("rowId"))
			grid.showColumn("rowId", {}, true);
		grid.refreshColumns();
		view.callEvent("onCommand", [{id:"toolbar-update", name:"hide-headers", value:false}]);
		view.callEvent("onAction", ["header-hide", {newValue:false, value:true}]);
	}
}

export function serialize(view, data){
	data.table.headers = view.$$("cells").config.header?1:0;
}

export function load(view, data){
	var mode = false;
	if(data.table && !webix.isUndefined(data.table.headers))
		mode = !data.table.headers;
	hideHeaders(view, mode);
}