import {Dialog} from "./common";
import * as fmt from "../operations/formats";
import group from "../operations/undo_group";

export const action = "custom";

export class DialogBox extends Dialog{
	$show(){
		this.form = this.$dialog.$$("form");
		this.list = this.$dialog.$$("list");
		this.preview = this.$dialog.$$("preview");

		this.cell = this.view.getSelectedId(true);
		if (!this.cell.length)
			return false;

		this.fillForm(this.view);

		this.view.$handleSelection = function(){
			return false;
		};
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	$init(){
		const i18n = webix.i18n.spreadsheet;
		const labelsLocale = i18n.labels;
		const formatsLocale = i18n.formats;

		const docsLink = "https://webix-guides.gitbook.io/spreadsheet-user-guide/formatting_numbers#custom-number-format";
		const delimiters = fmt.getDelimiters("int");

		const commonConfig = [
			{
				name:"zeros",
				view:"counter",
				css:"webix_ssheet_counter",
				label:labelsLocale["decimal-places"],
				value:0,
				batch:"common"
			},
			{
				name: "separator",
				view:"checkbox",
				label:labelsLocale["separator"],
				batch:"common"
			},
			{
				view:"formlist",
				label:labelsLocale["negative"],
				name:"negative",
				batch:"common",
				css:"webix_ssheet_format_negative",
				template: obj => {
					if(this.list){
						let format = this.getFormat(obj.id);
						format = fmt.format2code(format.fmt, format.delimiters);
						const style = {css:""};
						const value = format(-1234.56789, style);

						return "<span class='"+style.css+"'>"+value+"</span>";
					}
				},
				data:[{id:1}, {id:2}, {id:3}]
			}
		];

		const customConfig = [
			{
				view:"text",
				label: labelsLocale["format-pattern"],
				name:"format",
				labelPosition:"top",
				batch:"custom",
				placeholder:"[>100]0"+delimiters.groupSign+"000"+delimiters.decimalSign+"00;[>0]None"
			},
			{
				id: "docs",
				template: "<a href='"+docsLink+"' target='_blank' class = 'docs'>"+labelsLocale["format-docs"]+"</a>",
				borderless:true,
				batch:"custom"
			}
		];

		const dateConfig = [
			{
				name:"date",
				batch:"date",
				view:"formlist",
				labelWidth: 90,
				height: 160,
				label:labelsLocale["date-format"],
				css:"webix_ssheet_format_date",
				template: obj => fmt.format2code(obj.value, delimiters)(45000),
				data:[
					formatsLocale.dateFormat,
					formatsLocale.timeFormat,
					formatsLocale.fullDateFormat,
					formatsLocale.longDateFormat
				]
			}
		];

		return {
			view: "ssheet-dialog",
			position:"center",
			width:460,
			head: labelsLocale["format-title"],
			move: true,
			body:{
				cols:[
					{
						view:"list",
						id:"list",
						css:"webix_ssheet_format_type",
						width:120,
						scroll:false,
						data:this.getFormats(),
						select:true,
						on:{
							onSelectChange: (id)=>{
								this.updateForm();
								switch(id[0]){
									case "custom":
									case "date":
										this.form.showBatch(id[0]);
										break;
									default:
										this.form.showBatch("common");
										this.form.elements.separator.show();
										if(id[0] == "percent")
											this.form.elements.separator.hide();
								}
							}
						}
					},
					{
						view:"form",
						id:"form",
						margin:15,
						height:270,
						paddingY:0,
						paddingX:20,
						elements:[
							{
								id: "preview",
								template: function(obj){
									if(obj.value == i18n.table["format-error"])
										return obj.value;
									return "<span class ='"+obj.css+"'>"+obj.value+"</span>";
								},
								css: "webix_ssheet_format_preview",
								autoheight: true,
								borderless:true
							}
						].concat(commonConfig, customConfig, dateConfig),
						elementsConfig: {
							labelWidth: 155
						},
						on: {
							onChange: () => this.updateForm()
						}
					}
				]
			},
			on:{
				onSaveClick: () => this.okClick(),
				onHideClick: () => this.close(),
				onCancelClick: () => this.closeWin()
			}
		};
	}
	okClick(){
		let type = this.list.getSelectedId();
		let values = (type == "custom")?{format:this.form.getValues().format}:this.form.getValues();
		values.type = type;

		group.set(()=>{
			for (let i = 0; i < this.cell.length; i++)
				fmt.addFormat(this.view, this.cell[i].row, this.cell[i].column, values.format, values);
		});

		this.view.$$("cells").refresh();
		this.closeWin();
	}
	closeWin(){
		const style = this.view.getStyle(this.cell[0].row, this.cell[0].column);
		const name = style && style.props.format ? style.props.format : "common";

		//default format
		if(!fmt.isCustom(name))
			this.view.callEvent("onCommand", [{id:"toolbar-update", name:"format", value:name}]);
		this.close();
	}
	getFormat(negative){
		const type = this.list.getSelectedId();
		let values = this.form.getValues();

		values.negative = negative || values.negative;
		if(type != "custom"){
			if(type != "date")
				values.date = "";

			values = fmt.formToValues(type, values);
			return {
				fmt: fmt.serializeFormat(values),
				delimiters:fmt.getDelimiters(type)
			};
		}
		else
			return {
				fmt: values.format,
				delimiters: fmt.getDelimiters(type)
			};
	}
	fillForm(view){
		const locale = webix.i18n.spreadsheet;
		const style = view.getStyle(this.cell[0].row, this.cell[0].column);
		let values = { zeros:0, separator: 0, negative: 1, date: locale.formats.dateFormat, format: ""};
		let type;

		let name = style && style.props.format ? style.props.format : "";

		values.format = fmt.getFormatSource(name || "price");

		if(!name)
			type = name = "price";
		else if(name == "string")
			type = name = "custom";
		else
			type = fmt.formatHelpers[name].values.type;

		if(type != "custom")
			webix.extend(values, fmt.formatHelpers[name].values, true);

		const item = this.view.$$("cells").getItem(this.cell[0].row);
		const col = this.cell[0].column;

		this.value = webix.isUndefined(item[col]) ? "" : item[col];

		this.form.setValues(values);
		this.list.select(type);
		this.updateForm();
	}
	updateForm(){
		const form = this.form.elements;
		const format = this.getFormat();
		let value = this.value;

		const isText = value === "" || isNaN(value);

		if(fmt.checkFormat(format, "date") && (isText || fmt.isDateInvalid(value)))
			value = 45000;
		else if(isText && !fmt.checkFormat(format, "string"))
			value = 1234.56789;

		let style = {css:""};
		value = fmt.format2code(format.fmt, format.delimiters)(value, style);

		//preview values
		this.preview.parse({value:value, css:style.css});
		form.negative.refresh();
		form.date.refresh();

		//set new format string
		form.format.setValue(format.fmt);
	}
	getFormats(){
		const locale = webix.i18n.spreadsheet.labels;
		return [
			{id: "price", value: locale.currency},
			{id: "int", value: locale.number},
			{id: "percent", value: locale.percent},
			{id: "date", value: locale.date},
			{id: "custom", value: locale["custom-format"]}
		];
	}
}