import * as box from "./imagecell";

export const action = "add-image-top";

export class DialogBox extends box.DialogBox{
	open(action){
		this.config = action.config;
		this.viewid = action.viewid;
		box.DialogBox.prototype.open.apply(this, arguments);
	}
	$hide(){
		this.config = this.viewid = null;
	}
	$show(box, form){

		form.elements.preview.setHTML("");
		form.elements.url.setValue("");

		if(this.config && this.config.data)
			form.elements.url.setValue(this.config.data);
	
		form.elements.url.focus();
	}
	okClick(){
		const image = this.fillUrl(this.$dialog.getBody().elements.url.getValue());
		if(this.viewid)
			this.view.views.update(this.viewid, image);
		else
			this.view.views.add(null, null, "image", image);
		this.close();
	}
}