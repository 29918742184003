webix.protoUI({
	name: "ssheet-form-popup",
	defaults:{
		padding:0,
		borderless: true
	},
	$init: function(){
		this.$view.className += " webix_ssheet_form";
	}
}, webix.ui.suggest);

webix.protoUI({
	name: "ssheet-form-suggest",
	defaults:{
		padding:0,
		borderless: true
	},
	$init: function(){
		this.$view.className += " webix_ssheet_suggest";
	}
}, webix.ui.suggest);

webix.protoUI({
	$cssName: "colorpicker",
	name: "ssheet-colorpicker",
	$init: function(){
		this.$view.className += " webix_ssheet_colorpicker";
	},
	defaults:{
		suggest:{
			borderless: true,
			body:{
				view: "ssheet-colorboard",
			}
		}
	}
}, webix.ui.colorpicker);